import { CSSProperties, ForwardedRef, forwardRef, memo } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useFade from './hooks/useFade';
import { FadeProps } from './types';

const defaultStyle = (duration: number) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
});

const transitionStyles: {
  [key in TransitionStatus]: CSSProperties;
} = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

function Fade(
  {
    className,
    durationIn = 1000,
    durationOut = 1000,
    appear,
    in: inProp,
    children,
    onClick,
    onExited,
    unmountOnExit = true,
    style = EMPTY_OBJ,
  }: FadeProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { nodeRef, setNodeRef } = useFade(ref);

  return (
    <Transition
      appear={appear}
      in={inProp}
      nodeRef={nodeRef}
      onExited={onExited}
      timeout={{
        enter: durationIn,
        exit: durationOut,
      }}
      unmountOnExit={unmountOnExit}
    >
      {(state) => (
        <div
          ref={setNodeRef}
          className={className}
          onClick={onClick}
          style={{
            ...style,
            ...defaultStyle(inProp ? durationIn : durationOut),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}

export default memo(forwardRef(Fade));
