/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { NOOP } from '@kitted/shared-utils';

import useComponentId from '../../hooks/useComponentId';
import useInput from './hooks/useInput';
import InputLabel from './InputLabel';
import { InputProps } from './types';

const Input = ({
  wrapperClassName,
  errors,
  disabled,
  label,
  value,
  onChange,
  type,
  variant = 'solid',
  theme = 'secondary',
  isLoading = false,
  onSetIsLoading = NOOP,
  ...rest
}: InputProps) => {
  const {
    InputComponent,
    inputProps,
    touchedErrors,
    isFocused,
    handleOnFocus,
    handleOnBlur,
  } = useInput(type, errors);
  const componentId = useComponentId();

  return (
    <div className={wrapperClassName}>
      <InputLabel
        forInput={componentId}
        disabled={disabled}
        label={label}
        isRequired={rest.required}
        theme={theme}
        type={type}
      />
      <InputComponent
        {...rest}
        disabled={disabled}
        name={componentId}
        errors={touchedErrors}
        isFocused={isFocused}
        isLoading={isLoading}
        label={label}
        onBlur={handleOnBlur}
        onChange={onChange}
        onFocus={handleOnFocus}
        onSetIsLoading={onSetIsLoading}
        value={value}
        variant={variant}
        theme={theme}
        {...inputProps}
      />
    </div>
  );
};

export default memo(Input);
