import { useMemo } from 'react';

import { DeletedCardProps } from '../../components/DeletedCard/types';
import { getDeletedCardProps } from './logic';

export const useDeletedCard = ({
  imageAsset: _imageAsset,
  imageSrc: _imageSrc,
  baseUrl,
  ...props
}: DeletedCardProps) => {
  const { subType, type } = props;

  const deletedCardProps = useMemo(
    () => getDeletedCardProps(baseUrl, type, subType),
    [baseUrl, type, subType]
  );

  return {
    deletedCardProps: {
      ...props,
      ...deletedCardProps,
    },
  };
};
