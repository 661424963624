/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import FocusTrap from 'focus-trap-react';
import { Fade } from '@kitted/shared-components';

import useAlert from './hooks/useAlert';
import DefaultAlertContent from './DefaultAlertContent';
import useStyles from './styles';
import { AlertProps } from './types';

const Alert = (props: AlertProps) => {
  const { id, hasTopAlert, isIn = false } = props;
  const { onClose, onExited, preventClose } = useAlert(id, hasTopAlert);
  const styles = useStyles();

  return (
    <FocusTrap active={isIn}>
      <Fade
        className={styles.container}
        durationIn={500}
        durationOut={500}
        in={isIn}
        onClick={onClose}
        onExited={onExited}
      >
        <DefaultAlertContent
          {...props}
          onClose={onClose}
          preventClose={preventClose}
        />
      </Fade>
    </FocusTrap>
  );
};

export default memo(Alert);
