/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import { useDeletedCard } from '../../hooks/useDeletedCard';
import Card from '../Card';
import { DeletedCardProps } from './types';

const DeletedCard = (props: DeletedCardProps) => {
  const { deletedCardProps } = useDeletedCard(props);

  return <Card {...deletedCardProps} />;
};

export default memo(DeletedCard);
