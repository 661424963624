/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import Button from '../../../Button';
import { useFormSubmitButtonData } from '../../../Form/contexts/FormSubmitButtonContext';

const Reset = () => {
  const formSubmitButtonProps = useFormSubmitButtonData();

  return (
    <Button
      fullWidth
      theme="primary"
      variant="solid"
      {...formSubmitButtonProps}
    >
      Request Reset Link
    </Button>
  );
};

export default memo(Reset);
