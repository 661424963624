import { memo } from 'react';
import { Fade, Spinner, Stack, Typography } from '@kitted/shared-components';

import EllipsisDots from '../Loaders/EllipsisDots';
import useStyles from './styles';
import { LoadingOverlayProps } from './types';

const LoadingOverlay = ({
  title,
  in: isIn,
  subTitle = '',
  helperText = '',
}: LoadingOverlayProps) => {
  const styles = useStyles();

  return (
    <Fade className={styles.container} durationIn={500} in={isIn}>
      <Stack align="center" className={styles.stack} spacing="md">
        <Typography className={styles.heading} variant="heading5">
          {title}
          <EllipsisDots />
        </Typography>
        <Typography variant="panelBody">{subTitle}</Typography>
      </Stack>
      <Typography className={styles.helperText} variant="panelBody">
        {helperText}
      </Typography>
      <Spinner className={styles.spinner} />
    </Fade>
  );
};

export default memo(LoadingOverlay);
