import {
  deletedCardBody,
  deletedCardImageSrc,
  deletedCardTitle,
} from '../../components/DeletedCard/constants';
import { DeletedCardProps } from '../../components/DeletedCard/types';

export const getDeletedCardProps = (
  baseUrl: DeletedCardProps['baseUrl'],
  type: DeletedCardProps['type'],
  _subType: DeletedCardProps['subType']
) => {
  switch (type) {
    case 'tool':
    case 'kit':
    case 'flow':
    case 'author':
    case 'article':
    case 'play':
    case 'subject':
    case 'resource': // TODO: @haxxxton - implement this
    default:
      return {
        title: deletedCardTitle,
        body: deletedCardBody,
        imageSrc: `https://${baseUrl}${deletedCardImageSrc}`,
      };
  }
};
