/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Times } from '@kitted/website-icons';

import useLightbox from './hooks/useLightbox';
import useStyles from './styles';
import { LightboxModalProps } from './types';

const LightboxModalContent = ({
  preventClose,
  onClose,
  meta,
}: LightboxModalProps) => {
  const styles = useStyles();
  const { imgSrc, imageAsset, videoSrc, cardId } = meta;
  const { style, Component, componentProps, imageIsZoomed } = useLightbox(
    imgSrc,
    imageAsset,
    videoSrc,
    cardId
  );

  return (
    <>
      <button
        aria-label="Close"
        className={styles.closeButton}
        onClick={onClose}
        type="button"
      >
        <Times size="sm" />
      </button>
      <div
        className={clsx(
          styles.modal,
          (imgSrc || imageAsset) && styles.image,
          videoSrc && styles.video,
          imageIsZoomed && styles.zoomedImage
        )}
        onClick={preventClose}
        style={style}
      >
        {Component && <Component {...componentProps} />}
        {/* TODO: title as attribution */}
      </div>
    </>
  );
};

export default memo(LightboxModalContent);
