import { useContext } from 'react';

import { AnalyticsContext } from '../../AnalyticsContext';

const useAnalyticsApi = () => {
  const analyticsService = useContext(AnalyticsContext);
  return analyticsService;
};

export default useAnalyticsApi;
