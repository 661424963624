import { EMPTY_ARR, EMPTY_OBJ } from '@kitted/shared-utils';

import { FormStates } from '../../../contexts/FormContext';
import { isRequirableValidator } from '../../../services/helpers/validators';
import { FieldProps } from './types';

const getIsRequired = (
  validation: FieldProps['validation'] = EMPTY_ARR
): boolean =>
  validation.findIndex(
    (validator) => isRequirableValidator(validator) && validator.isRequired
  ) >= 0;

const getHasMaxLengthValue = (
  validation: FieldProps['validation'] = EMPTY_ARR
): number | undefined =>
  validation.find((validator) => validator.name === 'hasMaxLength')?.maxLength;

export const getInputProps = (
  formState: FormStates,
  validation: FieldProps['validation'] = EMPTY_ARR,
  customAttributes: FieldProps['customAttributes'] = EMPTY_OBJ
) => {
  let inputProps = customAttributes;
  const hasRequired = getIsRequired(validation);
  if (hasRequired) {
    inputProps = {
      ...inputProps,
      required: true,
    };
  }
  const maxLengthValue = getHasMaxLengthValue(validation);
  if (maxLengthValue) {
    inputProps = {
      ...inputProps,
      maxLength: maxLengthValue,
    };
  }
  if (formState === FormStates.SUBMITTING) {
    inputProps = {
      ...inputProps,
      disabled: true,
    };
  }
  return inputProps;
};
