import { memo } from 'react';
import clsx from 'clsx';

import Image from '../../Image';
import useStyles from './styles';
import { CardImageProps } from './types';

const CardImage = ({
  className,
  halfHeight,
  isFeatured,
  imageSrc,
  loaderTheme,
}: CardImageProps) => {
  const styles = useStyles();

  return (
    <Image
      className={clsx(
        className,
        styles.container,
        isFeatured && styles.containerIsFeatured,
        halfHeight && styles.halfHeight
      )}
      loaderTheme={loaderTheme}
      src={imageSrc}
    />
  );
};

export default memo(CardImage);
