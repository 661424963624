/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import CardContents from '../CardContents';
import CardContentsForHoverableCard from '../CardContentsForHoverableCard';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicArticleCardProps } from './types';

const ArticleCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  isActive,
  isFeatured,
  isMenuOpen,
  imageSrc,
  title,
  ...rest
}: PolymorphicArticleCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isActive && styles.containerActive,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen
      )}
      {...interactionProps}
    >
      <CardImage
        halfHeight={isFeatured}
        imageSrc={imageSrc}
        isFeatured={isFeatured}
      />
      {isFeatured ? (
        <CardContents body={body} isFeatured title={title} type="article" />
      ) : (
        <CardContentsForHoverableCard
          body={body}
          isActive={isActive}
          isFeatured={false}
          isMenuOpen={isMenuOpen}
          title={title}
          type="article"
        />
      )}
      {children}
    </Component>
  );
};

export default memo(ArticleCard);
