import { AuthorModel } from '@kitted/author-service-models';
import {
  extractCodeFromSlug,
  getFullName,
  slugize,
} from '@kitted/shared-utils';
import { interpolateRoutePath, routes } from '@kitted/website-constants';

import { ActionItem } from '../../../hooks/cardActions/constants/cardActions/types';

export const getAuthorPath = (
  pathGenerator: typeof interpolateRoutePath,
  item: ActionItem
) => {
  const { firstName, lastName, slug } = item as AuthorModel;

  return pathGenerator(routes.authorTitleCode, {
    code: extractCodeFromSlug(slug),
    titleSlug: slugize(getFullName(firstName, lastName)),
  });
};
