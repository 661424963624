import { memo } from 'react';

import { FormProvider } from '../../contexts/FormContext';
import PreloadRoutes from '../../contexts/RoutePreloadContext/PreloadRoutes';
import EditDrawerSpace from '../EditDrawerSpace';
import LoadingOverlay from '../LoadingOverlay';
import useModelCreateEdit from './hooks/useModelCreateEdit';
import {
  dataSchema,
  DEFAULT_MODEL_CREATE_INITIAL_DATA,
  EDIT_ROUTE_KEYS,
  modelCreateEditSectionDefaultSlug,
  modelCreateEditSections,
} from './constants';
import ModelCreateEditFormFooter from './ModelCreateEditFormFooter';
import { ModelCreateEditProps } from './types';

const ModelCreateEdit = ({
  initialData = DEFAULT_MODEL_CREATE_INITIAL_DATA,
  id,
  cardType,
  isCloning,
  onSaveChanges,
  onClose,
  title,
}: ModelCreateEditProps) => {
  const { spaceProps, showLoading, loadingText, action } = useModelCreateEdit(
    title,
    id,
    cardType,
    isCloning,
    onSaveChanges,
    onClose
  );

  return (
    <FormProvider dataSchema={dataSchema} initialValues={initialData}>
      <EditDrawerSpace
        footer={ModelCreateEditFormFooter}
        initialSelectedDrawerItemSlug={modelCreateEditSectionDefaultSlug}
        isDisabled
        items={modelCreateEditSections}
        spaceProps={spaceProps}
      />
      <PreloadRoutes routeKeys={EDIT_ROUTE_KEYS} />
      <LoadingOverlay
        in={showLoading}
        subTitle={`We’re ${action} things for you. Please don’t navigate away from this page.`}
        title={loadingText}
      />
    </FormProvider>
  );
};

export default memo(ModelCreateEdit);
