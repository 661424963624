import { memo } from 'react';

import WebsiteIcon from './WebsiteIcon';
import { WebsiteIconProps } from './types';

const SquovalFinger: React.FC<WebsiteIconProps> = ({
  color = 'currentColor',
  ...props
}: WebsiteIconProps) => (
  <WebsiteIcon
    viewBox="0 0 26 26"
    {...props}
  >
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75">
      <path vectorEffect="non-scaling-stroke" stroke={color} d="M13.68 13.79H4.26a3.24 3.24 0 0 1-3.24-3.24V4.28a3.24 3.24 0 0 1 3.24-3.24h17.47a3.24 3.24 0 0 1 3.24 3.24v6.27a3.24 3.24 0 0 1-3.24 3.24h-1.84" /><path vectorEffect="non-scaling-stroke" stroke={color} d="m16.32 24.96-4.89-4.57c-.46-.5-.36-1.22.07-1.66.49-.49 1.28-.5 1.79-.05l2.22 2.06-.07-8.25c0-.65.52-1.17 1.29-1.17.83 0 1.34.56 1.34 1.17l-.02 4.25 4.67.72c1.04.28 1.56 1.16 1.52 2.13l-.36 5.19" />
    </g>
  </WebsiteIcon>
);

export default memo(SquovalFinger);
