import { memo } from 'react';
import { Divider } from '@kitted/shared-components';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useCreateUserModalContent from './hooks/useCreateUserModalContent';
import { dataSchema, layoutSchema } from './constants';
import CreateAndLoginButton from './CreateAndLoginButton';
import useStyles from './styles';
import Terms from './Terms';
import { AuthRegisterModalProps } from './types';

const AuthCreateUserModalContent = ({
  onClose,
  meta,
  preventClose,
  title,
  isRequired,
}: AuthRegisterModalProps) => {
  const styles = useStyles();
  const { initialFormData, onSubmit, onSubmitSuccess, progressPercentage } =
    useCreateUserModalContent(onClose, meta);

  return (
    <AuthenticationModalContentWrapper
      isRequired={isRequired}
      onClose={onClose}
      preventClose={preventClose}
      title={title}
    >
      <FormProvider dataSchema={dataSchema} initialValues={initialFormData}>
        <Form
          autoComplete
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <FormFields layout={layoutSchema} />
          <Terms onClick={onClose} />
          <Divider
            className={styles.divider}
            isSubtle
            spacing="lg"
            theme="primary"
          />
          <CreateAndLoginButton
            onClose={onClose}
            progressPercentage={progressPercentage}
          />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthCreateUserModalContent);
