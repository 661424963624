import { convertVideoResourceModelToVideoResourceCardModel } from '@kitted/card-service-shared';
import { KittedServiceEnum } from '@kitted/kitted-models';
import {
  VideoResourceModel,
  VideoResourceSaveModel,
} from '@kitted/resource-service-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useVideoResourceRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { platformRequest } = useRequests();

  const [getVideoResourceBySlug, videoResourceBySlugFetchState] =
    useAsyncRequest<VideoResourceModel<false>>(
      (slug: VideoResourceModel['slug']) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'GET',
          url: `/v1/videoResource/slug/${slug}/published`,
        })
    );

  const [getVideoResource, videoResourceFetchState] = useAsyncRequest<
    VideoResourceModel<false>
  >((id: VideoResourceModel['id']) =>
    platformRequest(KittedServiceEnum.Resource, {
      method: 'GET',
      url: `/v1/videoResource/${id}/published`,
    })
  );

  const [cloneVideoResource, videoResourceCloneState] =
    useAuthedAsyncRequest<VideoResourceModel>(
      (data: VideoResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/videoResource/clone',
        }),
      (response: VideoResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertVideoResourceModelToVideoResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [draftSavePublishVideoResource, videoResourceDraftSavePublishState] =
    useAuthedAsyncRequest<VideoResourceModel>(
      (data: VideoResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/videoResource/draftSavePublish',
        }),
      (response: VideoResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertVideoResourceModelToVideoResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [createPublishVideoResource, videoResourceCreatePublishState] =
    useAuthedAsyncRequest<VideoResourceModel>(
      (data: VideoResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/videoResource/createPublish',
        }),
      (response: VideoResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertVideoResourceModelToVideoResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [deleteVideoResource, videoResourceDeleteState] =
    useAuthedAsyncRequest<VideoResourceModel>(
      (videoResourceId: VideoResourceModel['id']) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'DELETE',
          url: `/v1/videoResource/${videoResourceId}`,
        }),
      (response: VideoResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertVideoResourceModelToVideoResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  return {
    getVideoResourceBySlug,
    videoResourceBySlugFetchState,
    getVideoResource,
    videoResourceFetchState,
    cloneVideoResource,
    videoResourceCloneState,
    draftSavePublishVideoResource,
    videoResourceDraftSavePublishState,
    createPublishVideoResource,
    videoResourceCreatePublishState,
    deleteVideoResource,
    videoResourceDeleteState,
  };
};

export default useVideoResourceRequests;
