import { memo } from 'react';
import { Navigate } from 'react-router-dom';

import useRedirectTo from './hooks/useRedirectTo';
import { RedirectToProps } from './types';

const RedirectTo = ({ to }: RedirectToProps) => {
  const { redirectPath } = useRedirectTo(to);

  return <Navigate replace to={redirectPath} />;
};

export default memo(RedirectTo);
