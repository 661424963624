import { ArticleModel } from '@kitted/article-service-models';
import { FlowModel } from '@kitted/flow-service-models';
import { KitModel } from '@kitted/kit-service-models';
import { CardType } from '@kitted/kitted-models';
import { PlayModel } from '@kitted/play-service-models';
import {
  ImageResourceModel,
  LinkResourceModel,
  ResourceType,
  VideoResourceModel,
} from '@kitted/resource-service-models';
import { EMPTY_OBJ, EMPTY_PROMISE } from '@kitted/shared-utils';

import { ModelCreateEditFormData } from '../../../../../../../components/ModelCreateEdit/types';
import { RequestFn } from '../../../../../../../hooks/useAsyncRequest/types';
import { getModelTitleFromCardTypeAndResourceType } from '../../../../../../../services/helpers/string';
import { UserCreateableModel } from '../../types';

export const getPayloadFromFormData = (
  formData: ModelCreateEditFormData,
  additionalPayloadData: Record<string, unknown> = EMPTY_OBJ
) => {
  const { keyImage, ...remainingForm } = formData;
  if (keyImage) {
    // if we've uploaded a new keyImage
    if (keyImage?.startsWith('T::')) {
      return {
        ...additionalPayloadData,
        ...remainingForm,
        keyImageImageAsset: undefined,
        keyImageBlobId: keyImage,
      };
    }
    return {
      ...additionalPayloadData,
      ...remainingForm,
      keyImageBlobId: undefined,
      keyImageImageAsset: {
        id: keyImage,
      },
    };
  }
  return {
    ...additionalPayloadData,
    ...remainingForm,
  };
};

export const getModalTitleForCardType = (
  cardType: CardType,
  resourceType?: ResourceType
) => {
  const modelTitle = getModelTitleFromCardTypeAndResourceType(
    cardType,
    resourceType
  );

  return `Create new ${modelTitle}`;
};

export const getCreatorRequestForCardType = ({
  cardType,
  resourceType,
  createPublishArticle,
  createPublishPlay,
  createPublishFlow,
  createPublishKit,
  createPublishImageResource,
  createPublishLinkResource,
  createPublishVideoResource,
}: {
  cardType: CardType;
  resourceType?: ResourceType;
  createPublishArticle: RequestFn<ArticleModel | undefined>;
  createPublishPlay: RequestFn<PlayModel | undefined>;
  createPublishFlow: RequestFn<FlowModel | undefined>;
  createPublishKit: RequestFn<KitModel | undefined>;
  createPublishImageResource: RequestFn<ImageResourceModel | undefined>;
  createPublishLinkResource: RequestFn<LinkResourceModel | undefined>;
  createPublishVideoResource: RequestFn<VideoResourceModel | undefined>;
}): RequestFn<UserCreateableModel | undefined> => {
  if (cardType === CardType.Article) {
    return createPublishArticle;
  }
  if (cardType === CardType.Play) {
    return createPublishPlay;
  }
  if (cardType === CardType.Flow) {
    return createPublishFlow;
  }
  if (cardType === CardType.Kit) {
    return createPublishKit;
  }
  if (cardType === CardType.Resource) {
    switch (resourceType) {
      case ResourceType.Image:
        return createPublishImageResource;
      case ResourceType.Link:
        return createPublishLinkResource;
      case ResourceType.Video:
        return createPublishVideoResource;
      default:
        return EMPTY_PROMISE;
    }
  }
  return EMPTY_PROMISE;
};
