import { memo } from 'react';
import { TextSkeleton } from '@kitted/shared-components';

import MainMenuItem from '../../MainMenuItem';
import useLoginOutMenuItem from './hooks/useLoginOutMenuItem';

const LoginOutMenuItem = () => {
  const { onClick, title, icon, isLoggingIn } = useLoginOutMenuItem();
  return (
    <MainMenuItem icon={icon} onClick={onClick} title={title}>
      {isLoggingIn ? <TextSkeleton lines={1} variant="navLink" /> : title}
    </MainMenuItem>
  );
};

export default memo(LoginOutMenuItem);
