import { memo } from 'react';
import clsx from 'clsx';
import { styling } from '@kitted/design-system';
import { KittedBrandingFull } from '@kitted/shared-components';

import { useDrawerSpaceContainerData } from '../context/DrawerSpaceContainerContext';
import useStyles from './styles';

const DefaultDrawerHeader = () => {
  const { isMainOpen } = useDrawerSpaceContainerData();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <span
        className={clsx(styles.branding, isMainOpen && styles.brandingOpen)}
      >
        <KittedBrandingFull
          fill={styling.colors.theme.secondary}
          useBrandColors
        />
      </span>
    </div>
  );
};

export default memo(DefaultDrawerHeader);
