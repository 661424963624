import { memo } from 'react';
import clsx from 'clsx';
import { styling } from '@kitted/design-system';
import { KitteditorBrandingFull } from '@kitted/shared-components';

import { useDrawerSpaceContainerData } from '../../DrawerSpace/context/DrawerSpaceContainerContext';
import useStyles from './styles';

const DefaultEditDrawerHeader = () => {
  const { isMainOpen } = useDrawerSpaceContainerData();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <span
        className={clsx(styles.branding, isMainOpen && styles.brandingOpen)}
      >
        <KitteditorBrandingFull
          fill={styling.colors.theme.secondary}
          useBrandColors
        />
      </span>
    </div>
  );
};

export default memo(DefaultEditDrawerHeader);
