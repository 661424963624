import { memo } from 'react';
import { RenewCircle } from '@kitted/website-icons';

import Button from '../../../../../../../../components/Button';
import useVideoScrapeButton from './hooks/useVideoScrapeButton';
import useStyles from './styles';

const VideoScrapeButton = () => {
  const styles = useStyles();
  const { onScrape, isLoading, isDisabled } = useVideoScrapeButton();

  return (
    <div>
      <Button
        className={styles.container}
        iconLeft={RenewCircle}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onClick={onScrape}
        theme="secondary"
        variant="outline"
      >
        Fetch details
      </Button>
    </div>
  );
};

export default memo(VideoScrapeButton);
