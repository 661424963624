/* eslint-disable react/jsx-props-no-spreading */
import { ForwardedRef, forwardRef, memo } from 'react';
import clsx from 'clsx';
import { Fade, Spinner } from '@kitted/shared-components';

import useButton from './hooks/useButton';
import useButtonLoadingTheme from './hooks/useButtonLoadingTheme';
import useStyles from './styles';
import { ButtonProps } from './types';

function Button(
  {
    className,
    children,
    autoFocus,
    iconLeft: IconLeft,
    iconRight: IconRight,
    loadingComponent: LoadingComponent,
    isDisabled,
    isLoading,
    fullWidth,
    onClick,
    onFocus,
    onBlur,
    href,
    to,
    type,
    target,
    tabIndex,
    style,
    theme = 'secondary',
    variant = 'outline',
    size = 'xs',
    alignment = 'center',
  }: ButtonProps,
  ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
) {
  const styles = useStyles();
  const { ButtonComponent, buttonProps } = useButton({
    onClick,
    href,
    to,
    isDisabled,
    type,
    target,
  });
  const { loadingTheme } = useButtonLoadingTheme(variant, theme);

  return (
    <ButtonComponent
      ref={ref}
      autoFocus={autoFocus}
      className={clsx(
        className,
        styles.container,
        fullWidth && styles.fullWidth,
        styles[`theme-${theme}`],
        styles[`variant-${variant}`],
        styles[`size-${size}`],
        styles[`alignment-${alignment}`],
        isDisabled && styles.isDisabled,
        isLoading && styles.isLoading
      )}
      disabled={isLoading}
      onBlur={onBlur}
      onFocus={onFocus}
      style={style}
      tabIndex={tabIndex}
      {...buttonProps}
    >
      {IconLeft && <IconLeft className={styles.icon} size="inherit" />}
      <span className={styles.content}>{children}</span>
      {IconRight && <IconRight className={styles.icon} size="inherit" />}
      <Fade
        className={styles.loadingSpinner}
        durationIn={300}
        durationOut={300}
        in={!!isLoading}
      >
        {LoadingComponent ? (
          <LoadingComponent />
        ) : (
          <Spinner size={100} theme={loadingTheme} />
        )}
      </Fade>
    </ButtonComponent>
  );
}

export default memo(forwardRef(Button));
