/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import CardContents from '../CardContents';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicAuthorCardProps } from './types';

const AuthorCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  slug,
  imageSrc,
  isFeatured,
  isMenuOpen,
  subType,
  title,
  ...rest
}: PolymorphicAuthorCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        'inverted',
        styles.container,
        isFeatured && styles.containerFeatured,
        isMenuOpen && styles.containerMenuOpen
      )}
      {...interactionProps}
    >
      <CardImage
        className={styles.image}
        halfHeight
        imageSrc={imageSrc}
        isFeatured={isFeatured}
        loaderTheme="primary"
      />
      <CardContents
        body={body}
        isFeatured={isFeatured}
        isHover
        title={title}
        type="author"
      />
      {children}
    </Component>
  );
};

export default memo(AuthorCard);
