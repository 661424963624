/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Stack, Typography } from '@kitted/shared-components';
import { NOOP } from '@kitted/shared-utils';

import Button from '../../../Button';
import { useFormSubmitButtonData } from '../../../Form/contexts/FormSubmitButtonContext';
import useLoginCreateButton from './hooks/useLoginCreateButton';
import useStyles from './styles';
import { LoginCreateButtonProps } from './types';

const LoginCreateButton = ({ onClose }: LoginCreateButtonProps) => {
  const { isSubmitting, ...rest } = useFormSubmitButtonData();
  const { onJoinBeta } = useLoginCreateButton(onClose);
  const styles = useStyles();

  return (
    <Stack direction="column" spacing="xs">
      <Button fullWidth theme="primary" variant="solid" {...rest}>
        Login
      </Button>
      {/* <Typography variant="inputLabel">
        Don’t have an account?{' '}
        <Typography
          className={clsx(
            styles.createAccount,
            isLoading && styles.createAccountDisabled
          )}
          as="button"
          onClick={isLoading ? NOOP : onCreateAccount}
          variant="inlineAction"
          type="button"
        >
          Create account
        </Typography>
      </Typography> */}
      <Typography className={styles.join} variant="inputLabel">
        Don’t have an account?{' '}
        <Typography
          as="button"
          className={clsx(
            styles.createAccount,
            isSubmitting && styles.createAccountDisabled
          )}
          onClick={isSubmitting ? NOOP : onJoinBeta}
          type="button"
          variant="inlineAction"
        >
          Join the Beta
        </Typography>
      </Typography>
    </Stack>
  );
};

export default memo(LoginCreateButton);
