import { convertLinkResourceModelToLinkResourceCardModel } from '@kitted/card-service-shared';
import { KittedServiceEnum } from '@kitted/kitted-models';
import {
  LinkResourceModel,
  LinkResourceSaveModel,
} from '@kitted/resource-service-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useLinkResourceRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { platformRequest } = useRequests();

  const [getLinkResourceBySlug, linkResourceBySlugFetchState] = useAsyncRequest<
    LinkResourceModel<false>
  >((slug: LinkResourceModel['slug']) =>
    platformRequest(KittedServiceEnum.Resource, {
      method: 'GET',
      url: `/v1/linkResource/slug/${slug}/published`,
    })
  );

  const [getLinkResource, linkResourceFetchState] = useAsyncRequest<
    LinkResourceModel<false>
  >((id: LinkResourceModel['id']) =>
    platformRequest(KittedServiceEnum.Resource, {
      method: 'GET',
      url: `/v1/linkResource/${id}/published`,
    })
  );

  const [cloneLinkResource, linkResourceCloneState] =
    useAuthedAsyncRequest<LinkResourceModel>(
      (data: LinkResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/linkResource/clone',
        }),
      (response: LinkResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertLinkResourceModelToLinkResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [draftSavePublishLinkResource, linkResourceDraftSavePublishState] =
    useAuthedAsyncRequest<LinkResourceModel>(
      (data: LinkResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/linkResource/draftSavePublish',
        }),
      (response: LinkResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertLinkResourceModelToLinkResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [createPublishLinkResource, linkResourceCreatePublishState] =
    useAuthedAsyncRequest<LinkResourceModel>(
      (data: LinkResourceSaveModel) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'POST',
          data,
          url: '/v1/linkResource/createPublish',
        }),
      (response: LinkResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertLinkResourceModelToLinkResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  const [deleteLinkResource, linkResourceDeleteState] =
    useAuthedAsyncRequest<LinkResourceModel>(
      (linkResourceId: LinkResourceModel['id']) =>
        platformRequest(KittedServiceEnum.Resource, {
          method: 'DELETE',
          url: `/v1/linkResource/${linkResourceId}`,
        }),
      (response: LinkResourceModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertLinkResourceModelToLinkResourceCardModel(
              response.id,
              response
            ),
            response.id
          );
        }
      }
    );

  return {
    getLinkResourceBySlug,
    linkResourceBySlugFetchState,
    getLinkResource,
    linkResourceFetchState,
    cloneLinkResource,
    linkResourceCloneState,
    draftSavePublishLinkResource,
    linkResourceDraftSavePublishState,
    createPublishLinkResource,
    linkResourceCreatePublishState,
    deleteLinkResource,
    linkResourceDeleteState,
  };
};

export default useLinkResourceRequests;
