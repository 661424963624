import { memo } from 'react';
import clsx from 'clsx';
import { Fade, Spinner, Typography } from '@kitted/shared-components';

import AssetImage from '../AssetImage';
import useAccountAvatar from './hooks/useAccountAvatar';
import { AccountAvatarSize } from './Enum';
import useStyles from './styles';
import { AccountAvatarProps } from './types';

const AccountAvatar = ({
  className,
  imageAsset,
  src,
  initials,
  isLoading = false,
  size = AccountAvatarSize.Small,
}: AccountAvatarProps) => {
  const styles = useStyles();
  const { spinnerSize } = useAccountAvatar(size);

  return (
    <Typography
      as="div"
      className={clsx(className, styles.container, styles[`size-${size}`])}
    >
      <Fade
        className={styles.loadingSpinner}
        durationIn={300}
        durationOut={300}
        in={isLoading || !initials}
      >
        <Spinner size={spinnerSize} theme="primary" />
      </Fade>
      <AssetImage
        className={styles.accountImage}
        imageAsset={imageAsset}
        size={size}
        src={src}
      />
      <Fade
        className={styles.loadingSpinner}
        durationIn={300}
        durationOut={300}
        in={!isLoading && !!initials}
      >
        {!imageAsset && !src && initials && (
          <span className={styles.initials}>{initials}</span>
        )}
      </Fade>
    </Typography>
  );
};

export default memo(AccountAvatar);
