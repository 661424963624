import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { JwtPayload } from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';
import { init, reset, setUserId, track } from '@amplitude/analytics-browser';
import { useIsServer } from '@kitted/shared-components';

import config from '../../../../constants/config';
import useAuthTokensApi from '../../../AuthTokensContext/hooks/useAuthTokensApi';
import { useGlobalScriptVariables } from '../../../GlobalScriptVariablesContext';
import { AnalyticsEvents } from '../../types';
import {
  handleAmplitudeOnMount,
  handleIdentifyUser,
  handleTrackEvent,
} from './logic';

const amplitude = {
  init,
  setUserId,
  reset,
  track,
};

const useAnalyticsManagement = () => {
  const isServer = useIsServer();
  const { KITTED_ENVIRONMENT } = useGlobalScriptVariables();
  const isProduction = useMemo(
    () => KITTED_ENVIRONMENT === 'production',
    [KITTED_ENVIRONMENT]
  );
  const enabledRef = useRef<{
    amplitude: boolean;
  }>({
    amplitude: false,
  });
  const { getTokens } = useAuthTokensApi();

  const setEnabledRef = useCallback(
    (key: 'amplitude') => (isEnabled: boolean) => {
      enabledRef.current[key] = isEnabled;
    },
    []
  );

  useEffect(() => {
    if (isServer) return;
    const idToken = getTokens()?.idToken;
    let userId;
    if (idToken) {
      userId = jwtDecode<JwtPayload>(idToken)?.sub;
    }
    // amplitude
    handleAmplitudeOnMount(
      amplitude.init,
      config.keys.amplitude,
      userId,
      isProduction,
      setEnabledRef('amplitude')
    );
  }, [setEnabledRef, getTokens, isServer, isProduction]);

  const identifyUser = useCallback(
    (userId: string | undefined, userInfo?: Record<string, string>) => {
      // amplitude
      handleIdentifyUser(
        enabledRef.current.amplitude,
        amplitude.setUserId.bind(amplitude),
        amplitude.reset,
        userId
      );
    },
    []
  );

  const trackEvent = useCallback(
    (
      eventDetails: AnalyticsEvents,
      eventProperties?: Record<string, unknown>
    ) => {
      // amplitude
      handleTrackEvent(
        enabledRef.current.amplitude,
        amplitude.track,
        eventDetails,
        eventProperties
      );
    },
    []
  );

  const getDistinctId = useCallback(() => '', []);

  return {
    trackEvent,
    identifyUser,
    getDistinctId,
  };
};

export default useAnalyticsManagement;
