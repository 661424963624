/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';
import { Times } from '@kitted/website-icons';

import useMediaQueryManagement from '../../contexts/MediaQueryManagementContext/hooks/useMediaQueryManagement';
import Button from '../Button';
import IconButton from '../IconButton';
import useBannerIcon from './hooks/useBannerIcon';
import useStyles from './styles';
import { BannerProps } from './types';

const Banner = ({
  className,
  children,
  buttons,
  onClose,
  onMouseEnter,
  onMouseLeave,
  theme,
  title,
  body,
  icon,
}: BannerProps) => {
  const { IconComponent } = useBannerIcon(icon, theme);
  const { isDesktop } = useMediaQueryManagement();
  const styles = useStyles();

  return (
    <div
      className={clsx(className, styles.container, styles[`theme-${theme}`])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      <div className={styles.iconContainer}>
        <div className={styles.icon}>
          <IconComponent preserveAspectRatio="xMidYMin meet" size="lg" />
        </div>
      </div>
      <div className={styles.message}>
        <Typography variant="toastTitle">{title}</Typography>
        {body && <Typography variant="toastBody">{body}</Typography>}
      </div>
      {buttons && (
        <div className={styles.buttonsContainer}>
          {buttons.map(({ title: buttonTitle, ...rest }, i) => (
            <Button
              key={buttonTitle}
              {...rest}
              fullWidth={!isDesktop}
              size="xs"
              theme={theme === 'secondary' ? 'primary' : 'secondary'}
              variant={i === 0 ? 'solid' : 'outline'}
            >
              {buttonTitle}
            </Button>
          ))}
        </div>
      )}
      {onClose && (
        <div className={styles.closeButtonContainer}>
          <IconButton
            className={styles.closeButton}
            icon={Times}
            onClick={onClose}
            theme="secondary"
            variant="outline"
          />
        </div>
      )}
    </div>
  );
};

export default memo(Banner);
