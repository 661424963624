import { memo } from 'react';
import { NOOP } from '@kitted/shared-utils';

import CreateModelPopoverContent from '../../MainMenuDrawer/AccountMenu/CreateModelFlyout/CreateModelPopoverContent';
import useCreateModelFlyout from '../../MainMenuDrawer/AccountMenu/CreateModelFlyout/hooks/useCreateModelFlyout';
import useCreateModelRequests from '../../MainMenuDrawer/AccountMenu/CreateModelFlyout/hooks/useCreateModelRequests';
import useStyles from './styles';
import { CreateMenuContentProps } from './types';

const CreateMenuContent = ({ onToggleOpen }: CreateMenuContentProps) => {
  const { registerCreatedModel, onModalClose } = useCreateModelFlyout(true);
  const { onClick } = useCreateModelRequests(
    NOOP,
    registerCreatedModel,
    onModalClose
  );
  const styles = useStyles();

  return (
    <>
      <div className={styles.background} onClick={onToggleOpen} />
      <div className={styles.popoverContent}>
        <CreateModelPopoverContent mobileOnlyCreate onClick={onClick} />
      </div>
    </>
  );
};

export default memo(CreateMenuContent);
