// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepFreeze = (object: any) => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  propNames.forEach((name) => {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
};

export default deepFreeze;
