import { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { InternalRoute } from '@kitted/website-constants';

import { getTitleFromRouteTitle } from '../../../../components/SEOHead/hooks/usePageTitle/logic';
import menus from '../../../../constants/routes/menus';
import MainMenuItem from '../MainMenuItem';
import AccountMenuItem from './AccountMenuItem';
import CreateModelFlyout from './CreateModelFlyout';
import LoginOutMenuItem from './LoginOutMenuItem';
import useStyles from './styles';

const AccountMenu = () => {
  const styles = useStyles();

  return (
    <nav className={styles.container}>
      <ul>
        <CreateModelFlyout redirectOnCreate />
        {menus.accountMenu.map(({ route, icon }) => {
          const title = getTitleFromRouteTitle(route.title, EMPTY_OBJ, false);
          const isAuthenticated =
            'isAuthenticated' in route ? route.isAuthenticated : false;

          return (
            <MainMenuItem
              key={route.path}
              icon={icon}
              isAuthenticated={isAuthenticated}
              path={route.path}
              preinterpolatedRoutePath={
                (route as InternalRoute)?.preinterpolatedRoutePath
              }
              target={route.target}
              title={title}
            >
              {title}
            </MainMenuItem>
          );
        })}
        <LoginOutMenuItem />
        <AccountMenuItem />
      </ul>
    </nav>
  );
};

export default memo(AccountMenu);
