import { memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';

import useStyles from './styles';
import { CharacterCountProps } from './types';

const CharacterCount = ({
  isDisabled,
  maxLength,
  value,
}: CharacterCountProps) => {
  const styles = useStyles();
  const isErrored = maxLength && value.length > maxLength;

  return (
    <Typography
      as="div"
      className={clsx(
        styles.container,
        isDisabled && styles.containerDisabled,
        isErrored && styles.containerErrored
      )}
      variant="inputErrors"
    >
      {value.length}
      {maxLength && `/${maxLength}`}
    </Typography>
  );
};

export default memo(CharacterCount);
