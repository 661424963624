import { convertKitModelToKitCardModel } from '@kitted/card-service-shared';
import {
  AnyKitBlockModel,
  KitDraftSavePublishModel,
  KitMetadataModel,
  KitModel,
  KitSaveModel,
} from '@kitted/kit-service-models';
import {
  CardModelId,
  GenericHttpCompleteResponse,
  KittedServiceEnum,
} from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import { METADATA_KEY_SEPARATOR } from '../../metadata/useMetadata/constants';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const useKitRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { updateStoreSectionItem: updateMetadataStoreSectionItem } =
    useStoreSection('Metadata');
  const { platformRequest } = useRequests();

  const [getKitBySlug, kitBySlugFetchState] = useAsyncRequest<KitModel<false>>(
    (slug: KitModel['slug']) =>
      platformRequest(KittedServiceEnum.Kit, {
        method: 'GET',
        url: `/v1/kit/slug/${slug}/published`,
      })
  );

  const [getKit, kitFetchState] = useAsyncRequest<KitModel<false>>(
    (id: KitModel['id']) =>
      platformRequest(KittedServiceEnum.Kit, {
        method: 'GET',
        url: `/v1/kit/${id}/published`,
      })
  );

  const [getKitBlocks, kitBlocksFetchState] = useAsyncRequest<
    (AnyKitBlockModel<false> | AnyKitBlockModel<true>)[]
  >((id: KitModel['id']) =>
    platformRequest(KittedServiceEnum.Kit, {
      method: 'GET',
      url: `/v1/kit/${id}/published/blocks`,
    })
  );

  const [addToKit, addToKitFetchState] = useAsyncRequest<KitModel>(
    (kitId: KitModel['id'], cardId: CardModelId) =>
      platformRequest(KittedServiceEnum.Kit, {
        method: 'PUT',
        url: `/v1/kit/${kitId}/addCard/${cardId}`,
      })
  );

  const [cloneKit, kitCloneState] = useAuthedAsyncRequest<KitModel>(
    (data: KitSaveModel) =>
      platformRequest(KittedServiceEnum.Kit, {
        method: 'POST',
        data,
        url: '/v1/kit/clone',
      }),
    (response: KitModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertKitModelToKitCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createPublishKit, kitCreatePublishState] =
    useAuthedAsyncRequest<KitModel>(
      (data: KitSaveModel) =>
        platformRequest(KittedServiceEnum.Kit, {
          method: 'POST',
          data,
          url: '/v1/kit/createPublish',
        }),
      (response: KitModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertKitModelToKitCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [draftSavePublishKit, kitDraftSavePublishState] =
    useAuthedAsyncRequest<KitModel>(
      (data: KitDraftSavePublishModel) =>
        platformRequest(KittedServiceEnum.Kit, {
          method: 'POST',
          data,
          url: '/v1/kit/draftSavePublish',
        }),
      (response: KitModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertKitModelToKitCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [getKitMetadata, getKitMetadataState] = useAsyncRequest<
    Nullable<KitMetadataModel>
  >((kitId: KitModel['id']) =>
    platformRequest(KittedServiceEnum.Kit, {
      method: 'GET',
      url: `/v1/kit/${kitId}/metadata`,
    })
  );

  const [setKitMetadata, setKitMetadataState] =
    useAuthedAsyncRequest<GenericHttpCompleteResponse>(
      (kitId: KitModel['id'], data: KitMetadataModel) =>
        platformRequest(KittedServiceEnum.Kit, {
          method: 'POST',
          data,
          url: `/v1/kit/${kitId}/metadata`,
        }),
      (
        response: GenericHttpCompleteResponse | undefined,
        kitId: KitModel['id'],
        data: KitMetadataModel
      ) => {
        if (response?.done) {
          updateMetadataStoreSectionItem(
            data,
            `${KittedServiceEnum.Kit}${METADATA_KEY_SEPARATOR}${kitId}`
          );
        }
      }
    );

  const [deleteKit, kitDeleteState] = useAuthedAsyncRequest<KitModel>(
    (kitId: KitModel['id']) =>
      platformRequest(KittedServiceEnum.Kit, {
        method: 'DELETE',
        url: `/v1/kit/${kitId}`,
      }),
    (response: KitModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertKitModelToKitCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  return {
    getKitBySlug,
    kitBySlugFetchState,
    getKit,
    kitFetchState,
    getKitBlocks,
    kitBlocksFetchState,
    cloneKit,
    kitCloneState,
    createPublishKit,
    kitCreatePublishState,
    draftSavePublishKit,
    kitDraftSavePublishState,
    addToKit,
    addToKitFetchState,
    getKitMetadata,
    getKitMetadataState,
    setKitMetadata,
    setKitMetadataState,
    deleteKit,
    kitDeleteState,
  };
};

export default useKitRequests;
