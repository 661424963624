/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { TextSkeleton } from '@kitted/shared-components';

import Button from '../../../../../components/Button';
import useMobileMenuOption from './hooks/useMobileMenuOption';
import useStyles from './styles';
import { MobileMenuOptionProps } from './types';

const MobileMenuOption = ({
  icon: Icon,
  path,
  target,
  title,
  onClick,
  isLoading,
  highlightSubroutes,
  isSelected = false,
}: MobileMenuOptionProps) => {
  const styles = useStyles();
  const { buttonProps, isItemSelected, titleValue } = useMobileMenuOption(
    path,
    target,
    onClick,
    title,
    isLoading,
    isSelected,
    highlightSubroutes
  );

  return (
    <Button
      className={clsx(
        styles.container,
        isItemSelected && styles.containerSelected
      )}
      {...buttonProps}
      alignment="pullIconRight"
      fullWidth
      iconRight={Icon}
      size="xs"
      variant="ghost"
    >
      {isLoading && <TextSkeleton lines={1} />}
      {!isLoading && titleValue}
    </Button>
  );
};

export default memo(MobileMenuOption);
