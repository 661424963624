/* eslint-disable react/jsx-props-no-spreading */
import { ForwardedRef, forwardRef, memo } from 'react';
import { InternalLink, InternalLinkProps } from '@kitted/shared-components';

import usePreloadingInternalLink from './hooks/usePreloadingInternalLink';

function PreloadingInternalLink(
  { to, ...rest }: InternalLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  usePreloadingInternalLink(to);

  return <InternalLink {...rest} ref={ref} to={to} />;
}

export default memo(forwardRef(PreloadingInternalLink));
