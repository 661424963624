import { MouseEvent, useCallback, useRef } from 'react';

import useAlertApi from '../../../../contexts/AlertContext/hooks/useAlertApi';
import { handleOnClose, handleOnExited } from '../../logic';
import { AlertProps } from '../../types';

const useAlert = (
  id: AlertProps['id'],
  hasTopAlert: AlertProps['hasTopAlert']
) => {
  const { closeAlert, deregisterAlert } = useAlertApi();
  const hasRequestedCloseRef = useRef<boolean>(false);

  const setHasRequestedClose = useCallback((requestClose: boolean) => {
    hasRequestedCloseRef.current = requestClose;
  }, []);

  const onClose = useCallback(
    () => handleOnClose(closeAlert, setHasRequestedClose),
    [closeAlert, setHasRequestedClose]
  );

  const onExited = useCallback(
    () =>
      handleOnExited(
        id,
        deregisterAlert,
        hasTopAlert,
        hasRequestedCloseRef.current
      ),
    [id, deregisterAlert, hasTopAlert]
  );

  const preventClose = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return {
    onClose,
    onExited,
    preventClose,
  };
};

export default useAlert;
