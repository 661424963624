import { FieldValue } from '../../../contexts/FormContext';
import { ValidationFn } from './types';

export type ValidationItemMustMatchValue = {
  name: 'mustMatchValue';
  isRequired: boolean;
  valueToMatch: number | string;
  validator: ValidationFn;
};

export const mustMatchValue = (
  isValueRequired: boolean,
  valueToMatch: ValidationItemMustMatchValue['valueToMatch']
): ValidationItemMustMatchValue => ({
  name: 'mustMatchValue',
  isRequired: isValueRequired,
  valueToMatch,
  validator: (value: FieldValue = '') => {
    const isValid = value === valueToMatch;

    if (isValid) return undefined;
    return `Value must match “${valueToMatch}”`;
  },
});
