import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

const sharedStyle = {
  width: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
  height: 8,
  zIndex: 5,
  borderRadius: [0, 0, 6, 6],
  transition: styling.transitions.utils.generate(['box-shadow']),
  boxShadow: {
    x: 0,
    y: 1,
    spread: 0,
    blur: 0,
    color: styling.colors.theme.secondary,
  },
};

export default createUseStyles(
  {
    container: {
      ...sharedStyle,
      bottom: 5,
      '&:before': {
        ...sharedStyle,
        content: '""',
        bottom: 4,
      },
      '&:after': {
        ...sharedStyle,
        content: '""',
        bottom: -4,
      },
    },
    containerMenuOpen: {
      boxShadow: {
        x: 0,
        y: 1,
        spread: 0,
        blur: 0,
        color: styling.colors.theme.transparent,
      },
      '&:before, &:after': {
        boxShadow: {
          x: 0,
          y: 1,
          spread: 0,
          blur: 0,
          color: styling.colors.theme.transparent,
        },
      },
    },
  },
  { name: 'cardFooterStack' }
);
