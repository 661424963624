import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';
import { upperFirst } from '@kitted/shared-utils';

export const getModelTitleFromCardTypeAndResourceType = (
  cardType: CardType,
  resourceType?: ResourceType
) => {
  if (resourceType) {
    return `${upperFirst(resourceType)} ${upperFirst(cardType)}`;
  }
  return `${upperFirst(cardType)}`;
};
