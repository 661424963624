/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';
import { Card, CardProps, DeletedCard } from '@kitted/shared-components';

import usePreloadingCard from './hooks/usePreloadingCard';

const PreloadingCard = (props: CardProps) => {
  const { versionStatus } = props;
  const { baseUrl, preloadingProps } = usePreloadingCard(props);

  if (versionStatus === KittedVersionStatusTypeEnum.Deleted) {
    return <DeletedCard {...preloadingProps} baseUrl={baseUrl} />;
  }

  return <Card {...props} />;
};

export default memo(PreloadingCard);
