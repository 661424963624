import { useEffect, useMemo } from 'react';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';
import { CardProps } from '@kitted/shared-components';
import { interpolateRoutePath } from '@kitted/website-constants';

import { useRoutePreloadApi } from '../../../../contexts/RoutePreloadContext';
import useAuthor from '../../../../hooks/authors/useAuthor';
import useEnvironmentDomain from '../../../../hooks/useEnvironmentDomain';
import { getAuthorPath } from '../../../CardActionMenu/AuthorCardActionMenu/logic';

const usePreloadingCard = (props: CardProps) => {
  const { authorId } = props;
  const { preloadPath } = useRoutePreloadApi();
  const baseUrl = useEnvironmentDomain();
  const { author } = useAuthor(authorId);
  const preloadingProps: CardProps = useMemo(() => {
    const { to, versionStatus } = props;
    if (to && author && versionStatus === KittedVersionStatusTypeEnum.Deleted) {
      return {
        ...props,
        to: getAuthorPath(interpolateRoutePath, author),
      };
    }
    return {
      ...props,
    };
  }, [props, author]);

  useEffect(() => {
    if (preloadingProps.to) {
      preloadPath(preloadingProps.to.toString());
    }
  }, [preloadPath, preloadingProps.to]);

  return {
    baseUrl,
    preloadingProps,
  };
};

export default usePreloadingCard;
