import { memo } from 'react';

import EditDrawerSpace from '../EditDrawerSpace';
import {
  assetCatalogueSections,
  calalogueSelectorSectionDefaultSlug,
} from './constants/assetCatalogueSections';
import { AssetCatalogueProvider } from './contexts/AssetCatalogueProvider';
import useAssetCatalogue from './hooks/useAssetCatalogue';
import CatalogueFooter from './CatalogueFooter';
import { AssetCatalogueProps } from './types';

const AssetCatalogue = ({
  onSaveChanges,
  onClose,
  title,
  maxFileSize,
  acceptedFileFormats,
  initialCatalogueSectionSlug = calalogueSelectorSectionDefaultSlug,
  isMultiSelect = true,
}: AssetCatalogueProps) => {
  const { spaceProps } = useAssetCatalogue({
    onSaveChanges,
    onClose,
    title,
    maxFileSize,
    acceptedFileFormats,
    isMultiSelect,
  });

  return (
    <AssetCatalogueProvider>
      <EditDrawerSpace
        footer={CatalogueFooter}
        initialSelectedDrawerItemSlug={initialCatalogueSectionSlug}
        items={assetCatalogueSections}
        spaceProps={spaceProps}
      />
    </AssetCatalogueProvider>
  );
};

export default memo(AssetCatalogue);
