import { memo } from 'react';
import clsx from 'clsx';
import { Landscape, Trash } from '@kitted/website-icons';

import Button from '../../Button';
import InputLabel from '../InputLabel';
import InputWrapper from '../InputWrapper';
import { InputComponentProps } from '../types';
import useInputImage from './hooks/useInputImage';
import useInputImageFocusing from './hooks/useInputImageFocusing';
import useStyles from './styles';
import { InputImageMetaProp } from './types';

const InputImage = ({
  className,
  errors,
  disabled,
  isFocused,
  label,
  onBlur,
  onFocus,
  onChange,
  meta,
  name,
  required,
  value,
  theme,
  variant,
}: InputComponentProps & InputImageMetaProp) => {
  const styles = useStyles();
  const {
    onClear,
    onAddImage,

    buttonTheme,
  } = useInputImage({
    onChange,
    meta,
    theme,
  });
  const { clearButtonRef, addButtonRef } = useInputImageFocusing(!!value);

  const PreviewComponent = meta?.previewComponent;

  return (
    <InputWrapper
      className={clsx(
        className,
        styles.container,
        styles[`theme-${theme}`],
        isFocused && styles.focused,
        errors && errors?.length > 0 && styles.errored
      )}
      errors={errors}
      hasValue={!!value}
      isDisabled={disabled}
      isFocused={isFocused}
      theme={theme}
      variant={variant}
    >
      <div className={styles.internals}>
        {PreviewComponent ? (
          <PreviewComponent errors={errors} value={value} />
        ) : (
          <InputLabel
            className={styles.label}
            disabled={disabled}
            forInput={name} // this might need to be an id (componentId)
            isRequired={required}
            label={label}
            theme={theme}
          />
        )}
        {!value && (
          <Button
            ref={addButtonRef}
            autoFocus={meta?.autoFocus}
            className={styles.button}
            iconLeft={Landscape}
            isDisabled={disabled}
            onBlur={onBlur}
            onClick={onAddImage}
            onFocus={onFocus}
            theme={buttonTheme}
            variant="solid"
          >
            Add image
          </Button>
        )}
        {value && (
          <Button
            ref={clearButtonRef}
            autoFocus={meta?.autoFocus}
            className={styles.button}
            iconLeft={Trash}
            isDisabled={disabled}
            onBlur={onBlur}
            onClick={onClear}
            onFocus={onFocus}
            theme={buttonTheme}
            variant="outline"
          >
            Clear image
          </Button>
        )}
      </div>
    </InputWrapper>
  );
};

export default memo(InputImage);
