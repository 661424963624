import { memo } from 'react';

import useContextualModalTitle from '../../../contexts/ModalContext/hooks/useContextualModalTitle';
import Button from '../../Button';
import EditDrawerSpaceFooter from '../../EditDrawerSpace/EditDrawerSpaceFooter';
import { ResourceModelCreateModalSpaceProps } from '../types';
import useResourceModelCreateFormFooter from './hooks/useResourceModelCreateFormFooter';

const ResourceModelCreateFormFooter = ({
  onSaveChanges,
  onClose,
  isCloning,
  title,
}: ResourceModelCreateModalSpaceProps) => {
  const contextualTitle = useContextualModalTitle(title);
  const { onSubmit, isDisabled } = useResourceModelCreateFormFooter(
    isCloning,
    onSaveChanges,
    onClose
  );

  return (
    <EditDrawerSpaceFooter title={contextualTitle}>
      <Button fullWidth onClick={onClose} theme="primary" type="button">
        Cancel
      </Button>
      <Button
        fullWidth
        isDisabled={isDisabled}
        onClick={onSubmit}
        theme="primary"
        type="button"
        variant="solid"
      >
        {isCloning ? 'Clone' : 'Create'} Resource
      </Button>
    </EditDrawerSpaceFooter>
  );
};

export default memo(ResourceModelCreateFormFooter);
