/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import Tooltip from '../../../../../components/Tooltip';
import useMenuItemLink from './hooks/useMenuItemLink';
import useStyles from './styles';
import { MenuItemLinkProps } from './types';

const MenuItemLink = ({
  children,
  path,
  onClick,
  target,
  title,
  preinterpolatedRoutePath,
  highlightSubroutes,
  isDisabled,
  isSelected,
  hasTooltip,
}: MenuItemLinkProps) => {
  const styles = useStyles();
  const { Component, componentProps, linkIsSelected, titleValue } =
    useMenuItemLink(
      path,
      onClick,
      target,
      preinterpolatedRoutePath,
      highlightSubroutes,
      title,
      isSelected,
      isDisabled
    );

  return (
    <>
      <div
        className={clsx(styles.arrow, linkIsSelected && styles.arrowSelected)}
      />
      <Tooltip
        className={styles.container}
        content={titleValue}
        isDisabled={!hasTooltip}
        side="right"
        sideOffset={15}
      >
        <Component
          {...componentProps}
          className={clsx(
            styles.item,
            linkIsSelected && styles.itemSelected,
            isDisabled && styles.itemDisabled
          )}
        >
          {children}
        </Component>
      </Tooltip>
    </>
  );
};

export default memo(MenuItemLink);
