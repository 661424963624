import { Fragment, memo } from 'react';

import { DrawerSpaceContainerProvider } from './context/DrawerSpaceContainerContext';
import DefaultDrawerHeader from './DefaultDrawerHeader';
import DrawerSpaceContent from './DrawerSpaceContent';
import { DrawerSpaceProps } from './types';

const DrawerSpace = ({
  children,
  footer,
  footerHeight,
  isMainDisabled = true,
  mainDrawerContent = Fragment,
  mainDrawerHeader = DefaultDrawerHeader,
}: DrawerSpaceProps) => (
  <DrawerSpaceContainerProvider isMainDisabled={isMainDisabled}>
    <DrawerSpaceContent
      footer={footer}
      footerHeight={footerHeight}
      isMainDisabled={isMainDisabled}
      mainDrawerContent={mainDrawerContent}
      mainDrawerHeader={mainDrawerHeader}
    >
      {children}
    </DrawerSpaceContent>
  </DrawerSpaceContainerProvider>
);

export default memo(DrawerSpace);
