import { memo } from 'react';
import { Magnify } from '@kitted/website-icons';

import Input from '../../../Input';
import { searchTermMeta } from './constants';
import useStyles from './styles';
import { SearchTermProps } from './types';

const SearchTermInput = ({ onChange, value }: SearchTermProps) => {
  const styles = useStyles();
  return (
    <Input
      className={styles.input}
      iconLeft={Magnify}
      meta={searchTermMeta}
      name="search"
      onChange={onChange}
      type="text"
      value={value}
      variant="hollow"
    />
  );
};

export default memo(SearchTermInput);
