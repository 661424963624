/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Stack } from '@kitted/shared-components';

import Button from '../../../Button';
import { useFormSubmitButtonData } from '../../../Form/contexts/FormSubmitButtonContext';
import { ConfirmCancelButtonsProps } from './types';

const ConfirmCancelButtons = ({ onClose }: ConfirmCancelButtonsProps) => {
  const formSubmitButtonProps = useFormSubmitButtonData();

  return (
    <Stack direction="column" spacing="xs">
      <Button
        fullWidth
        theme="primary"
        variant="solid"
        {...formSubmitButtonProps}
      >
        Confirm
      </Button>
      <Button fullWidth onClick={onClose} theme="primary" variant="outline">
        Cancel
      </Button>
    </Stack>
  );
};

export default memo(ConfirmCancelButtons);
