import { memo } from 'react';

import useContextualModalTitle from '../../../contexts/ModalContext/hooks/useContextualModalTitle';
import Button from '../../Button';
import EditDrawerSpaceFooter from '../../EditDrawerSpace/EditDrawerSpaceFooter';
import { AssetCatalogueModalSpaceProps } from '../types';
import useCatalogueFooter from './hooks/useCatalogueFooter';

const CatalogueFooter = ({
  onSaveChanges,
  onClose,
  title,
  isMultiSelect,
}: AssetCatalogueModalSpaceProps) => {
  const contextualTitle = useContextualModalTitle(title);
  const { onSubmit, isDisabled } = useCatalogueFooter(
    onSaveChanges,
    onClose,
    isMultiSelect
  );

  return (
    <EditDrawerSpaceFooter title={contextualTitle}>
      <Button fullWidth onClick={onClose} theme="primary" type="button">
        Cancel
      </Button>
      <Button
        fullWidth
        isDisabled={isDisabled}
        onClick={onSubmit}
        theme="primary"
        type="button"
        variant="solid"
      >
        Save changes
      </Button>
    </EditDrawerSpaceFooter>
  );
};

export default memo(CatalogueFooter);
