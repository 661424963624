/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { Stack } from '@kitted/shared-components';
import { EMPTY_ARR } from '@kitted/shared-utils';

import Button from '../../Button';
import useAlertButtons from './hooks/useAlertButtons';
import { AlertButtonsProps } from './types';

const AlertButtons = ({ buttons = EMPTY_ARR, onClose }: AlertButtonsProps) => {
  const { getInteractionProps } = useAlertButtons(onClose);

  if (buttons.length === 0) return null;

  return (
    <Stack direction="column" justify="center" spacing="xs">
      {buttons.map(({ title, theme, ...rest }, i) => (
        <Button
          key={title}
          fullWidth
          theme={theme || 'primary'}
          variant={i === 0 ? 'solid' : 'outline'}
          {...getInteractionProps(rest)}
        >
          {title}
        </Button>
      ))}
    </Stack>
  );
};

export default memo(AlertButtons);
