import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { KittedBrandmarkProps } from './types';

const KittedBrandmark = ({
  className,
  height = 77,
  width = 77,
  useBrandColors = false,
  fill = styling.colors.theme.primary600,
}: KittedBrandmarkProps) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 7.7 7.7"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={useBrandColors ? styling.colors.theme.action : fill}>
      <path
        d="m2.02,1.3h1.61s.04-.02.04-.04v-.66s0-.03-.02-.04l-.46-.46s-.04-.02-.06,0l-1.14,1.14s0,.07.03.07Z"
        opacity={0.4}
      />
      <path d="m3.67,3.31v-1.61s-.02-.04-.04-.04h-1.61s-.05.04-.03.07l1.61,1.61s.07,0,.07-.03Z" />
      <path d="m4.1,1.01s-.07,0-.07.03v2.28s.04.05.07.03l1.14-1.14s.02-.04,0-.06l-1.14-1.14Z" />
    </g>
    <g fill={useBrandColors ? styling.colors.theme.snare : fill}>
      <path d="m4.39,3.67h1.61s.04-.02.04-.04v-1.61s-.04-.05-.07-.03l-1.61,1.61s0,.07.03.07Z" />
      <path
        d="m7.61,3.13l-1.14-1.14s-.07,0-.07.03v1.61s.02.04.04.04h.66s.03,0,.04-.02l.46-.46s.02-.04,0-.06Z"
        opacity={0.4}
      />
      <path d="m5.55,5.24l1.14-1.14s0-.07-.03-.07h-2.28s-.05.04-.03.07l1.14,1.14s.04.02.06,0Z" />
    </g>
    <g fill={useBrandColors ? styling.colors.theme.insight : fill}>
      <path d="m3.6,6.69s.07,0,.07-.03v-2.28s-.04-.05-.07-.03l-1.14,1.14s-.02.04,0,.06l1.14,1.14Z" />
      <path d="m4.07,6.04h1.61s.05-.04.03-.07l-1.61-1.61s-.07,0-.07.03v1.61s.02.04.04.04Z" />
      <path
        d="m4.05,7.14l.46.46s.04.02.06,0l1.14-1.14s0-.07-.03-.07h-1.61s-.04.02-.04.04v.66s0,.03.02.04Z"
        opacity={0.4}
      />
    </g>
    <g fill={useBrandColors ? styling.colors.theme.frame : fill}>
      <path d="m3.31,4.03h-1.61s-.04.02-.04.04v1.61s.04.05.07.03l1.61-1.61s0-.07-.03-.07Z" />
      <path
        d="m.09,4.57l1.14,1.14s.07,0,.07-.03v-1.61s-.02-.04-.04-.04h-.66s-.03,0-.04.02l-.46.46s-.02.04,0,.06Z"
        opacity={0.4}
      />
      <path d="m2.15,2.46l-1.14,1.14s0,.07.03.07h2.28s.05-.04.03-.07l-1.14-1.14s-.04-.02-.06,0Z" />
    </g>
  </svg>
);

export default memo(KittedBrandmark);
