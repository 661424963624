import { memo } from 'react';

import useCardIcon from './hooks/useCardIcon';
import { CardIconProps } from './types';

const CardIcon: React.FC<CardIconProps> = ({
  children,
  className,
  height,
  width,
  viewBox,
  scale = 1,
  preserveAspectRatio = 'xMinYMid meet',
}: CardIconProps) => {
  const { calcedHeight, calcedWidth } = useCardIcon(height, width, scale);

  return (
    <svg
      className={className}
      height={calcedHeight}
      preserveAspectRatio={preserveAspectRatio}
      viewBox={viewBox}
      width={calcedWidth}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default memo(CardIcon);
