import { useMemo } from 'react';

import { DeletedCardWideProps } from '../../components/DeletedCardWide/types';
import { getDeletedCardWideProps } from './logic';

export const useDeletedCardWide = ({
  imageSrc: _imageSrc,
  baseUrl,
  ...props
}: DeletedCardWideProps) => {
  const { subType, type } = props;

  const deletedCardWideProps = useMemo(
    () => getDeletedCardWideProps(baseUrl, type, subType),
    [baseUrl, type, subType]
  );

  return {
    deletedCardWideProps: {
      ...props,
      ...deletedCardWideProps,
    },
  };
};
