import { memo } from 'react';
import clsx from 'clsx';
import { CardGrid } from '@kitted/shared-components';

import useFilterValues from './hooks/useFilterValues';
import FilterDropdown from './FilterDropdown';
import SearchTermInput from './SearchTermInput';
import useStyles from './styles';
import { SearchCatalogueTopBarProps } from './types';

const SearchCatalogueTopBar = ({
  hideFilterDropdown,
}: SearchCatalogueTopBarProps) => {
  const styles = useStyles();
  const { setFilter, setSearchText, filter, searchText, isTablet } =
    useFilterValues();

  return (
    <div className={styles.portal}>
      <div className={styles.container}>
        {isTablet && (
          <CardGrid className={styles.grid} needsFixerRow={false}>
            {/* subject filter */}
            {!hideFilterDropdown && (
              <div className={styles.subject}>
                <FilterDropdown setValue={setFilter} value={filter} />
              </div>
            )}
            <div
              className={clsx(
                styles.search,
                hideFilterDropdown && styles.searchSolo
              )}
            >
              {/* search term */}
              <SearchTermInput onChange={setSearchText} value={searchText} />
            </div>
          </CardGrid>
        )}
        {!isTablet && !hideFilterDropdown && (
          <div className={styles.subject}>
            <FilterDropdown setValue={setFilter} value={filter} />
          </div>
        )}
        {!isTablet && hideFilterDropdown && (
          <div className={styles.subject}>
            <SearchTermInput onChange={setSearchText} value={searchText} />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SearchCatalogueTopBar);
