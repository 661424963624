import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { KitteditorBrandProps } from './types';

const KitteditorBrand = ({
  className,
  height = 53,
  width = 269,
  fill = styling.colors.theme.primary600,
}: KitteditorBrandProps) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 269 53"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={fill}>
      <path d="M45.4,9.9l-2.4-2.4s0-.1,0-.2,0-.1,0-.2l2.4-2.4.2-.2.3-.3,2.4-2.4.2-.2.3-.3h.1c.1-.2.3-.2.4-.1l2.4,2.4.2.2,2.7,2.7.2.2.4.4h0c0,.1,0,.2,0,.3s0,0,0,.1c0,0,0,0,0,0l-.4.4-.2.2-2.7,2.7-.2.2-2.4,2.4s-.1,0-.2,0-.1,0-.2,0h0c0,0-.4-.4-.4-.4l-.2-.2-2.4-2.4-.4-.4-.2-.2h0Z" />
      <path d="M9.7,52H1.2c-.2,0-.3-.1-.3-.3V1.3c0-.2.1-.3.3-.3h8.7c.2,0,.3.1.3.3l-.2,23.7L30.3,1.3c.1-.2.3-.2.5-.2h9.6c.3,0,.4.3.2.5l-18.4,20.8,19.6,29.2c.1.2,0,.5-.3.5h-9.9c-.2,0-.5-.1-.6-.3l-15.2-22.3-5.9,6.7v15.6c0,.2-.1.3-.3.3h0Z" />
      <path d="M45,17.1h8.4c.2,0,.3.1.3.3v34.3c0,.2-.1.3-.3.3h-8.4c-.2,0-.3-.1-.3-.3V17.4c0-.2.1-.3.3-.3Z" />
      <path d="M115.7,52.8c-11.2,0-17-7.5-17-18.6s6.2-18.7,17-18.7,16.4,8,16.4,17.9v2.6c0,.1-.1.3-.3.3h-24c.4,6.7,3,10.3,8.3,10.3s6-2.2,6.7-4.3c0-.2.3-.4.5-.4h8.1c.2,0,.3.2.3.3-1.3,6.6-6.9,10.5-15.9,10.5ZM122.9,30.4c-.3-5.3-2.4-8.9-7.3-8.9s-7.1,3.5-7.8,9.3h15.1v-.4h0Z" />
      <path d="M168.9,52h-8c-.2,0-.3-.1-.3-.3v-5.2h-.2c-1.8,3.3-5.3,6.3-10.8,6.3-8.8,0-14.9-5.5-14.9-18.3s6.5-18.9,15.5-18.9,8.3,2.5,9.9,5.7h.1V1.3c0-.2.1-.3.3-.3h8.4c.2,0,.3.1.3.3v50.4c0,.2-.1.3-.3.3h0ZM160.3,32.7c0-6.7-3.2-10.7-8.1-10.7s-8.4,4.3-8.4,12.3,2.3,12.3,8.1,12.3,8.4-4.2,8.4-9.8v-4.1Z" />
      <path d="M96.5,45.4c-.7,0-1.7.2-2.6.2-3.6,0-5-1.5-5-5.2v-16.9h7.8c.2,0,.3-.1.3-.3v-5.8c0-.2-.1-.3-.3-.3h-8V7.6c0-.2-.1-.3-.3-.3h-7.2c-.2,0-.3.1-.3.3v1.8c0,6.6-.9,7.6-5.5,7.6h-7.2V7.6c0-.2-.1-.3-.3-.3h-8.1c-.2,0-.3.1-.3.3v34.4c0,7.4,3.7,10.5,10.8,10.5s4.7-.2,5.9-.4c.4,0,.6-.4.6-.7v-5.3c0-.4-.4-.7-.8-.7-.7,0-1.7.2-2.6.2-3.6,0-5-1.5-5-5.2v-16.9h11.6v18.5c0,7.4,3.7,10.5,10.8,10.5s4.7-.2,5.9-.4c.4,0,.6-.4.6-.7v-5.3c0-.4-.4-.7-.8-.7h0Z" />
      <path d="M247.5,16.5h7.7c.2,0,.4.2.4.4v6.8h.1c1.9-4.8,6.7-7.8,11.6-7.8h.3c.2,0,.3.1.3.3v7.8c0,.2-.1.3-.3.3h-1.4c-6.4,0-10.2,2.7-10.2,9.5v17.8c0,.2-.2.4-.4.4h-8.1c-.2,0-.4-.2-.4-.4V16.9c0-.2.2-.4.4-.4h0Z" />
      <path d="M209,34.2c0-11.4,6.6-18.6,17.4-18.6s17.3,7.1,17.3,18.6-6.9,18.7-17.3,18.7-17.3-7.2-17.3-18.7ZM218.3,34.2c0,7.5,2.3,12.4,8.1,12.4s8-5.1,8-12.4-2.2-12.4-8-12.4-8,5.1-8,12.4Z" />
      <path d="M190.7,23.5v18.5c0,7.4,3.7,10.5,10.8,10.5s4.7-.2,5.9-.4c.4,0,.6-.4.6-.7v-5.3c0-.4-.4-.7-.8-.7-.7,0-1.7.2-2.6.2-3.6,0-5-1.5-5-5.2v-16.9h7.8c.2,0,.3-.1.3-.3v-5.8c0-.2-.1-.3-.3-.3h-8V7.6c0-.2-.1-.3-.3-.3h-8.4c-.2,0,0,.1,0,.3v15.9h0Z" />
      <path d="M176.3,9.9l-2.4-2.4s0-.1,0-.2,0-.1,0-.2l2.4-2.4.2-.2.3-.3,2.4-2.4.2-.2.3-.3h.1c.1-.2.3-.2.4-.1l2.4,2.4.2.2,2.7,2.7.2.2.4.4h0c0,.1,0,.2,0,.3s0,0,0,.1c0,0,0,0,0,0l-.4.4-.2.2-2.7,2.7-.2.2-2.4,2.4s-.1,0-.2,0-.1,0-.2,0h0c0,0-.4-.4-.4-.4l-.2-.2-2.4-2.4-.4-.4-.2-.2h0Z" />
      <path d="M175.9,17.1h8.4c.2,0,.3.1.3.3v34.3c0,.2-.1.3-.3.3h-8.4c-.2,0-.3-.1-.3-.3V17.4c0-.2.1-.3.3-.3Z" />
    </g>
  </svg>
);

export default memo(KitteditorBrand);
