import { AlertContextApi } from '../../contexts/AlertContext/types';
import { AlertProps } from './types';

export const handleOnClose = (
  onClose: AlertContextApi['closeAlert'],
  setHasRequestedClose: (requestClose: boolean) => void
) => {
  setHasRequestedClose(true);
  onClose();
};

export const handleOnExited = (
  id: AlertProps['id'],
  deregisterAlert: AlertContextApi['deregisterAlert'],
  hasTopAlert: AlertProps['hasTopAlert'],
  requestedClose: boolean
) => {
  if (hasTopAlert && !requestedClose) return;
  deregisterAlert(id);
};
