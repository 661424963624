import { memo } from 'react';
import clsx from 'clsx';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';

import AccountAvatar from '../AccountAvatar';
import { AccountAvatarSize } from '../AccountAvatar/Enum';
import StatusDot from '../StatusDot';
import Tooltip from '../Tooltip';
import useCardAuthorAvatar from './hooks/useCardAuthorAvatar';
import CardAuthorAvatarTooltipContent from './CardAuthorAvatarTooltipContent';
import useStyles from './styles';
import { CardAuthorAvatarProps } from './types';

const CardAuthorAvatar = ({
  cardType,
  authorId,
  versionStatus,
  publishedAt,
}: CardAuthorAvatarProps) => {
  const styles = useStyles();
  const { contentRoot, shouldHide, author, initials, isMyAuthor } =
    useCardAuthorAvatar(cardType, authorId);

  if (shouldHide) {
    return null;
  }

  return (
    <Tooltip
      align="start"
      className={styles.wrapper}
      collisionBoundary={contentRoot}
      content={
        <CardAuthorAvatarTooltipContent
          author={author}
          initials={initials}
          publishedAt={publishedAt}
          versionStatus={versionStatus}
        />
      }
      isDisabled={!isMyAuthor}
      side="left"
      sideOffset={0}
      theme="white"
      tooltipClassName={styles.tooltip}
    >
      <StatusDot
        align="end"
        dotClassName={styles.dot}
        enabled={
          isMyAuthor && versionStatus === KittedVersionStatusTypeEnum.Draft
        }
        on="circle"
        side="right"
        status="warn"
      >
        <AccountAvatar
          className={clsx(
            styles.profileAvatar,
            !isMyAuthor && styles.disabledWrapper
          )}
          imageAsset={author?.keyImageImageAsset}
          initials={initials}
          size={AccountAvatarSize.Small}
        />
      </StatusDot>
    </Tooltip>
  );
};

export default memo(CardAuthorAvatar);
