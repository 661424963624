/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import Modal from '../Modal';
import useModalRoot from './hooks/useModalRoot';

const ModalRoot = () => {
  const { modals } = useModalRoot();

  return (
    <>
      {modals.map((modal, index) => {
        const modalKey = modal.key;
        const modalProps = modal.config || EMPTY_OBJ;
        return (
          <Modal
            key={modalKey}
            id={modalKey}
            {...modalProps}
            index={index}
            isTop={index === modals.length - 1}
          />
        );
      })}
    </>
  );
};

export default memo(ModalRoot);
