/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import { useDeletedCardWide } from '../../hooks/useDeletedCardWide';
import CardWide from '../CardWide';
import { DeletedCardWideProps } from './types';

const DeletedCardWide = (props: DeletedCardWideProps) => {
  const { deletedCardWideProps } = useDeletedCardWide(props);

  return <CardWide {...deletedCardWideProps} />;
};

export default memo(DeletedCardWide);
