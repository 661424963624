/* eslint-disable @typescript-eslint/no-explicit-any */
import * as amplitude from '@amplitude/analytics-browser';

import { AnalyticsApi, AnalyticsEvents } from '../../types';

export const handleAmplitudeOnMount = (
  initializeFn: (key: string, options: amplitude.Types.BrowserOptions) => void,
  apiKey: string,
  userId: string | undefined,
  isProduction: boolean,
  setEnabled: (enabled: boolean) => void
) => {
  if (initializeFn && apiKey && isProduction) {
    initializeFn(apiKey, {
      userId,
      // appVersion: '', // TODO: @haxxxton
      optOut: !(window as any)?.Termly?.getConsentState()?.analytics,
      identityStorage: 'localStorage',
      defaultTracking: {
        attribution: true,
        pageViews: {
          trackHistoryChanges: 'pathOnly',
        },
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
      },
    });
    setEnabled(true);
  }
};

export const handleTrackEvent = (
  isEnabled: boolean,
  trackEventFn: AnalyticsApi['trackEvent'],
  eventDetails: AnalyticsEvents,
  eventProperties?: Record<string, unknown>
) => {
  if (!isEnabled) return;
  trackEventFn(eventDetails, eventProperties);
};

export const handleIdentifyUser = (
  isEnabled: boolean,
  identifyUserFn: (userId: string, userInfo?: Record<string, string>) => void,
  resetUserFn: () => void,
  userId: string | undefined,
  userInfo?: Record<string, string>
) => {
  if (!isEnabled) return;
  if (userId) {
    identifyUserFn(userId, userInfo);
  } else {
    resetUserFn();
  }
};
