import { memo } from 'react';
import clsx from 'clsx';

import { useFormData } from '../../contexts/FormContext';
import { FormSubmitButtonProvider } from './contexts/FormSubmitButtonContext';
import useFormSubmit from './hooks/useFormSubmit';
import Captcha from './Captcha';
import useStyles from './styles';
import { FormProps } from './types';

const Form = ({
  className,
  children,
  onSubmit,
  onSubmitSuccess,
  onSubmitFail,
  autoComplete = false,
}: FormProps) => {
  const styles = useStyles();
  const { formInstanceId } = useFormData();

  const {
    hasCaptcha,

    registerExecuteCaptcha,
    onCaptchaVerified,
    onRecaptchaReady,

    handleOnSubmit,
  } = useFormSubmit(onSubmit, onSubmitSuccess, onSubmitFail);

  return (
    <FormSubmitButtonProvider>
      <form
        autoComplete={autoComplete ? 'on' : 'off'}
        className={clsx(className, styles.container)}
        id={formInstanceId}
        name={formInstanceId}
        onSubmit={handleOnSubmit}
      >
        {children}
        {hasCaptcha && (
          <Captcha
            onReady={onRecaptchaReady}
            onVerify={onCaptchaVerified}
            registerExecuteCaptcha={registerExecuteCaptcha}
          />
        )}
      </form>
    </FormSubmitButtonProvider>
  );
};

export default memo(Form);
