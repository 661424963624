/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';

import CardWideComponent from '../../../components/CardWide';
import DeletedCardWide from '../../../components/DeletedCardWide';
import { useCardWideComponentProps } from './hooks/useCardWideComponentProps';
import { RenderCardWideComponentProps } from './types';

const RenderCardWideComponent = (props: RenderCardWideComponentProps) => {
  const { card, baseUrl } = props;
  const cardWideProps = useCardWideComponentProps(props);

  if (card.versionStatus === KittedVersionStatusTypeEnum.Deleted) {
    return <DeletedCardWide {...cardWideProps} baseUrl={baseUrl} />;
  }

  return <CardWideComponent {...cardWideProps} />;
};

export default memo(RenderCardWideComponent);
