import { convertPlayModelToPlayCardModel } from '@kitted/card-service-shared';
import {
  GenericHttpCompleteResponse,
  KittedServiceEnum,
} from '@kitted/kitted-models';
import { Nullable } from '@kitted/platform-models';
import {
  AnyPlayBlockModel,
  AnyPlayBlockSaveModel,
  PlayMetadataModel,
  PlayModel,
  PlaySaveModel,
} from '@kitted/play-service-models';

import useRequests from '../../../contexts/RequestsContext/hooks/useRequests';
import useStoreSection from '../../../contexts/StoreContext/hooks/useStoreSection';
import { METADATA_KEY_SEPARATOR } from '../../metadata/useMetadata/constants';
import useAsyncRequest from '../../useAsyncRequest';
import useAuthedAsyncRequest from '../../useAuthedAsyncRequest';

const usePlayRequests = () => {
  const { updateStoreSectionItem } = useStoreSection('Cards');
  const { updateStoreSectionItem: updateMetadataStoreSectionItem } =
    useStoreSection('Metadata');
  const { platformRequest } = useRequests();

  const [getPlayLatest, playLatestFetchState] = useAuthedAsyncRequest<
    PlayModel<false>
  >((id: PlayModel['id']) =>
    platformRequest(KittedServiceEnum.Play, {
      method: 'GET',
      url: `/v1/play/${id}/latest`,
    })
  );

  const [getPlayBlocksLatest, playBlocksLatestFetchState] =
    useAuthedAsyncRequest<
      (AnyPlayBlockModel<false> | AnyPlayBlockModel<true>)[]
    >((id: PlayModel['id']) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'GET',
        url: `/v1/play/${id}/latest/blocks`,
      })
    );

  const [getPlayLatestBySlug, playLatestBySlugFetchState] =
    useAuthedAsyncRequest<PlayModel<false>>((slug: PlayModel['slug']) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'GET',
        url: `/v1/play/slug/${slug}/latest`,
      })
    );

  const [getPlayBySlug, playBySlugFetchState] = useAsyncRequest<
    PlayModel<false>
  >((slug: PlayModel['slug']) =>
    platformRequest(KittedServiceEnum.Play, {
      method: 'GET',
      url: `/v1/play/slug/${slug}/published`,
    })
  );

  const [getPlay, playFetchState] = useAsyncRequest<PlayModel<false>>(
    (id: PlayModel['id']) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'GET',
        url: `/v1/play/${id}/published`,
      })
  );

  const [getPlayBlocks, playBlocksFetchState] = useAsyncRequest<
    (AnyPlayBlockModel<false> | AnyPlayBlockModel<true>)[]
  >((id: PlayModel['id']) =>
    platformRequest(KittedServiceEnum.Play, {
      method: 'GET',
      url: `/v1/play/${id}/published/blocks`,
    })
  );

  const [clonePlay, playCloneState] = useAuthedAsyncRequest<PlayModel>(
    (data: PlaySaveModel) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'POST',
        data,
        url: `/v1/play/clone`,
      }),
    (response: PlayModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertPlayModelToPlayCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createPlay, playCreateState] = useAuthedAsyncRequest<PlayModel>(
    (data: PlaySaveModel) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'POST',
        data,
        url: `/v1/play/create`,
      }),
    (response: PlayModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertPlayModelToPlayCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [createPlayDraft, playDraftCreateState] =
    useAuthedAsyncRequest<PlayModel>(
      (id: PlayModel['id'], data: PlaySaveModel) =>
        platformRequest(KittedServiceEnum.Play, {
          method: 'POST',
          data,
          url: `/v1/play/${id}/draft`,
        })
    );

  const [createPlayAutosave, playAutosaveCreateState] =
    useAuthedAsyncRequest<PlayModel>((data: PlaySaveModel) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'POST',
        data,
        url: `/v1/play/autosave`,
      })
    );

  const [createPlayBlockAutosave, playBlockAutosaveCreateState] =
    useAuthedAsyncRequest<AnyPlayBlockModel>(
      (id: PlayModel['id'], data: AnyPlayBlockSaveModel) =>
        platformRequest(KittedServiceEnum.Play, {
          method: 'POST',
          data,
          url: `/v1/block/play/${id}/autosave`,
        })
    );

  const [publishPlay, playPublishState] = useAuthedAsyncRequest<PlayModel>(
    (id: PlayModel['id']) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'PUT',
        url: `/v1/play/${id}/publish`,
      })
  );

  const [createPublishPlay, playCreatePublishState] =
    useAuthedAsyncRequest<PlayModel>(
      (data: PlaySaveModel) =>
        platformRequest(KittedServiceEnum.Play, {
          method: 'POST',
          data,
          url: '/v1/play/createPublish',
        }),
      (response: PlayModel | undefined) => {
        if (response) {
          updateStoreSectionItem(
            convertPlayModelToPlayCardModel(response.id, response),
            response.id
          );
        }
      }
    );

  const [getPlayMetadata, getPlayMetadataState] = useAsyncRequest<
    Nullable<PlayMetadataModel>
  >((playId: PlayModel['id']) =>
    platformRequest(KittedServiceEnum.Play, {
      method: 'GET',
      url: `/v1/play/${playId}/metadata`,
    })
  );

  const [setPlayMetadata, setPlayMetadataState] =
    useAuthedAsyncRequest<GenericHttpCompleteResponse>(
      (playId: PlayModel['id'], data: PlayMetadataModel) =>
        platformRequest(KittedServiceEnum.Play, {
          method: 'POST',
          data,
          url: `/v1/play/${playId}/metadata`,
        }),
      (
        response: GenericHttpCompleteResponse | undefined,
        playId: PlayModel['id'],
        data: PlayMetadataModel
      ) => {
        if (response?.done) {
          updateMetadataStoreSectionItem(
            data,
            `${KittedServiceEnum.Play}${METADATA_KEY_SEPARATOR}${playId}`
          );
        }
      }
    );

  const [deletePlay, playDeleteState] = useAuthedAsyncRequest<PlayModel>(
    (playId: PlayModel['id']) =>
      platformRequest(KittedServiceEnum.Play, {
        method: 'DELETE',
        url: `/v1/play/${playId}`,
      }),
    (response: PlayModel | undefined) => {
      if (response) {
        updateStoreSectionItem(
          convertPlayModelToPlayCardModel(response.id, response),
          response.id
        );
      }
    }
  );

  const [deletePlayDraft, playDeleteDraftState] =
    useAuthedAsyncRequest<GenericHttpCompleteResponse>(
      (playId: PlayModel['id']) =>
        platformRequest(KittedServiceEnum.Play, {
          method: 'DELETE',
          url: `/v1/play/${playId}/draft`,
        }),
      (response: GenericHttpCompleteResponse | undefined) => !!response?.done
    );

  return {
    getPlayLatest,
    playLatestFetchState,
    getPlayBlocksLatest,
    playBlocksLatestFetchState,
    getPlayLatestBySlug,
    playLatestBySlugFetchState,
    getPlayBySlug,
    playBySlugFetchState,
    getPlay,
    playFetchState,
    getPlayBlocks,
    playBlocksFetchState,
    clonePlay,
    playCloneState,
    createPlay,
    playCreateState,
    createPlayDraft,
    playDraftCreateState,
    createPlayAutosave,
    playAutosaveCreateState,
    createPlayBlockAutosave,
    playBlockAutosaveCreateState,
    publishPlay,
    playPublishState,
    createPublishPlay,
    playCreatePublishState,
    getPlayMetadata,
    getPlayMetadataState,
    setPlayMetadata,
    setPlayMetadataState,
    deletePlay,
    playDeleteState,
    deletePlayDraft,
    playDeleteDraftState,
  };
};

export default usePlayRequests;
