import { memo } from 'react';
import clsx from 'clsx';
import { CaretDown } from '@kitted/website-icons';

import CollapseMenu from '../../../../CollapseMenu';
import InputSelectOption from '../../../../Input/InputSelect/InputSelectOption';
import useSelectStyles from '../../../../Input/InputSelect/styles';
import InputWrapper from '../../../../Input/InputWrapper';
import useSubjectFilterDropdown from './hooks/useSubjectFilterDropdown';
import MobileSubjectFilterDropdown from './MobileSubjectFilterDropdown';
import useStyles from './styles';
import { SubjectFilterDropdownProps } from './types';

const SubjectFilterDropdown = ({
  value,
  setValue,
}: SubjectFilterDropdownProps) => {
  const styles = useStyles();
  const selectStyles = useSelectStyles();
  const { options, isTablet, selectedItem, onButtonClick, isOpen, onClose } =
    useSubjectFilterDropdown(value, setValue);

  return (
    <>
      <InputWrapper
        className={styles.inputWrapper}
        hasValue
        iconRight={CaretDown}
        isFocused={false}
        variant="hollow"
      >
        <button
          className={selectStyles.toggleButton}
          onClick={onButtonClick}
          tabIndex={0}
          type="button"
        >
          {selectedItem?.title ? `#${selectedItem.title}` : ''}
        </button>
        {isTablet && (
          <CollapseMenu isOpen={isOpen} onClickOutside={onClose}>
            {options?.map((option) => (
              <li
                key={option.value}
                className={clsx(
                  selectStyles.item,
                  selectedItem?.value === option.value &&
                    selectStyles.itemSelected
                )}
              >
                <InputSelectOption
                  isSelected={selectedItem?.value === option.value}
                  onClick={option.onClick}
                  option={option}
                />
              </li>
            ))}
          </CollapseMenu>
        )}
      </InputWrapper>
      {!isTablet && (
        <MobileSubjectFilterDropdown isOpen={isOpen} onClickOutside={onClose}>
          {options?.map((option) => (
            <li
              key={option.value}
              className={clsx(
                selectStyles.item,
                selectedItem?.value === option.value &&
                  selectStyles.itemSelected,
                styles.selectOption
              )}
            >
              <InputSelectOption
                isSelected={selectedItem?.value === option.value}
                onClick={option.onClick}
                option={option}
              />
            </li>
          ))}
        </MobileSubjectFilterDropdown>
      )}
    </>
  );
};

export default memo(SubjectFilterDropdown);
