/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';

import CardComponent from '../../../components/Card';
import DeletedCard from '../../../components/DeletedCard';
import { useCardComponentProps } from './hooks/useCardComponentProps';
import { RenderCardComponentProps } from './types';

const RenderCardComponent = (props: RenderCardComponentProps) => {
  const { card, baseUrl } = props;
  const cardProps = useCardComponentProps(props);

  if (card.versionStatus === KittedVersionStatusTypeEnum.Deleted) {
    return (
      <DeletedCard
        {...cardProps}
        baseUrl={baseUrl}
        isFeatured
        nonInteractive
        preventActive
      />
    );
  }

  return (
    <CardComponent {...cardProps} isFeatured nonInteractive preventActive />
  );
};

export default memo(RenderCardComponent);
