import { createUseStyles } from 'react-jss';
import { styling } from '@kitted/design-system';

export default createUseStyles(
  {
    container: {
      color: styling.colors.theme.primary200,
      position: 'absolute',
      bottom: -23,
      right: 0,
      textAlign: 'right',
    },
    containerDisabled: {
      display: 'none',
    },
    containerErrored: {
      color: styling.colors.theme.error,
    },
  },
  { name: 'characterCount' }
);
