/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { CardType } from '@kitted/kitted-models';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import useBlockInteraction from '../../hooks/useBlockInteraction';
import useCardMenu from './hooks/useCardMenu';
import useCardType from './hooks/useCardType';
import CardFooter from './CardFooter';
import CardSlug from './CardSlug';
import useStyles from './styles';
import { CardProps } from './types';

const Card = ({
  className,
  isFeatured,
  slug,
  subType,
  type,
  imageSrc,
  videoUrl,
  label,
  nonInteractive,
  href,
  to,
  body,
  title,
  showTitle,
  preventActive,
  onClick,
  menu: CardMenu,
  menuProps = EMPTY_OBJ,
  target,
  isSelected,
  autoPlay,
  actionButtonTitle,
  authorAvatar: AuthorAvatar,
  authorId,
  versionStatus,
  publishedAt,
}: CardProps) => {
  const { CardComponent } = useCardType(type);
  const { isActive, interactionProps } = useBlockInteraction({
    nonInteractive,
    href,
    to,
    onClick,
    preventActive,
    target,
  });
  const { isMenuOpen, onMenuOpenToggle } = useCardMenu();
  const styles = useStyles();

  if (!CardComponent) return null;
  return (
    <div
      className={clsx(
        className,
        styles.container,
        isFeatured && styles.isFeatured,
        isActive && styles.isActive,
        isSelected && styles.isSelected
      )}
      data-cy="card"
    >
      {AuthorAvatar && (
        <AuthorAvatar
          authorId={authorId}
          cardType={type}
          publishedAt={publishedAt}
          versionStatus={versionStatus}
        />
      )}
      <CardComponent
        className={clsx(
          styles.card,
          nonInteractive && styles.cardNonInteractive,
          CardType.Display === type && styles.cardWithVideo
        )}
        {...interactionProps}
        actionButtonTitle={actionButtonTitle}
        autoPlay={autoPlay}
        body={body}
        imageSrc={imageSrc}
        isActive={isActive}
        isFeatured={isFeatured}
        isMenuOpen={isMenuOpen}
        label={label}
        showTitle={showTitle}
        slug={slug}
        subType={subType}
        title={title}
        videoUrl={videoUrl}
      >
        {slug && (
          <CardSlug
            className={styles.cardSlug}
            isFeatured={isFeatured}
            type={type}
          >
            {slug}
          </CardSlug>
        )}
        <CardFooter
          isFeatured={isFeatured}
          isMenuOpen={isMenuOpen}
          subType={subType}
          type={type}
        />
      </CardComponent>
      {CardMenu && type !== CardType.Display && (
        <CardMenu
          {...menuProps}
          isFeatured={isFeatured}
          isOpen={isMenuOpen}
          onToggle={onMenuOpenToggle}
        />
      )}
    </div>
  );
};

export default memo(Card);
