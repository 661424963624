import { memo } from 'react';
import clsx from 'clsx';
import { CardType } from '@kitted/kitted-models';
import { ResourceType } from '@kitted/resource-service-models';
import {
  Chain,
  LandscapeCard,
  Newspaper,
  Nodes,
  Playbook,
  ToolboxPlus,
  VideoPlay,
} from '@kitted/website-icons';

import MainMenuItem from '../../../MainMenuItem';
import useStyles from './styles';
import { CreateModelPopoverContentProps } from './types';

const CreateModelPopoverContent = ({
  className,
  onClick,
  mobileOnlyCreate = false,
}: CreateModelPopoverContentProps) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.container, className)}>
      <ul className={styles.list}>
        <MainMenuItem
          icon={ToolboxPlus}
          onClick={onClick(CardType.Kit)}
          title="Create Kit"
        >
          Kit
        </MainMenuItem>
        {!mobileOnlyCreate && (
          <MainMenuItem
            icon={Nodes}
            onClick={onClick(CardType.Flow)}
            title="Create Flow"
          >
            Flow
          </MainMenuItem>
        )}
        {!mobileOnlyCreate && (
          <MainMenuItem
            icon={Newspaper}
            onClick={onClick(CardType.Article)}
            title="Create Article"
          >
            Article
          </MainMenuItem>
        )}
        {!mobileOnlyCreate && (
          <MainMenuItem
            icon={Playbook}
            onClick={onClick(CardType.Play)}
            title="Create Play"
          >
            Play
          </MainMenuItem>
        )}
        <MainMenuItem
          icon={LandscapeCard}
          onClick={onClick(CardType.Resource, ResourceType.Image)}
          title="Create Image Resource"
        >
          Image Resource
        </MainMenuItem>
        <MainMenuItem
          icon={Chain}
          onClick={onClick(CardType.Resource, ResourceType.Link)}
          title="Create Link Resource"
        >
          Link Resource
        </MainMenuItem>
        <MainMenuItem
          icon={VideoPlay}
          onClick={onClick(CardType.Resource, ResourceType.Video)}
          title="Create Video Resource"
        >
          Video Resource
        </MainMenuItem>
      </ul>
    </div>
  );
};

export default memo(CreateModelPopoverContent);
