import { memo } from 'react';

import { LevelProps } from './types';

const Level = ({
  component: Component,
  childComponent,
  children,
}: LevelProps) => (
  <Component>
    {childComponent && (
      <Level
        childComponent={childComponent.childComponent}
        component={childComponent.component}
      >
        {children}
      </Level>
    )}
    {!childComponent && children}
  </Component>
);

export default memo(Level);
