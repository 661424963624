import { memo } from 'react';
import { Divider } from '@kitted/shared-components';

import { FormProvider } from '../../../contexts/FormContext';
import Form from '../../Form';
import FormFields from '../../FormFields';
import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useLoginModalContent from './hooks/useLoginModalContent';
import { dataSchema, layoutSchema } from './constants';
import ForgotPasswordLink from './ForgotPasswordLink';
import LoginCreateButton from './LoginCreateButton';
import useStyles from './styles';
import { AuthLoginModalProps } from './types';

const AuthLoginModalContent = ({
  onClose,
  preventClose,
  title,
  isRequired,
}: AuthLoginModalProps) => {
  const styles = useStyles();
  const { onSubmit, onSubmitSuccess } = useLoginModalContent(onClose);

  return (
    <AuthenticationModalContentWrapper
      isRequired={isRequired}
      onClose={onClose}
      preventClose={preventClose}
      title={title}
    >
      <FormProvider dataSchema={dataSchema}>
        <Form
          autoComplete
          onSubmit={onSubmit}
          onSubmitSuccess={onSubmitSuccess}
        >
          <FormFields layout={layoutSchema} />
          <ForgotPasswordLink onClick={onClose} />
          <Divider
            className={styles.divider}
            isSubtle
            spacing="lg"
            theme="primary"
          />
          <LoginCreateButton onClose={onClose} />
        </Form>
      </FormProvider>
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthLoginModalContent);
