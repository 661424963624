import { memo } from 'react';
import { styling } from '@kitted/design-system';
import { KittedVersionStatusTypeEnum } from '@kitted/kitted-models';
import { Divider, Typography } from '@kitted/shared-components';
import { Eye, Reset, Trash, TriangleExclaim } from '@kitted/website-icons';

import AccountAvatar from '../../AccountAvatar';
import { AccountAvatarSize } from '../../AccountAvatar/Enum';
import useCardAuthorAvatarTooltipContent from './hooks/useCardAuthorAvatarTooltipContent';
import useStyles from './styles';
import { CardAuthorAvatarTooltipContentProps } from './types';

const CardAuthorAvatarTooltipContent = ({
  author,
  initials,
  versionStatus,
  publishedAt,
}: CardAuthorAvatarTooltipContentProps) => {
  const { publishedDate } = useCardAuthorAvatarTooltipContent(publishedAt);
  const styles = useStyles();

  return (
    <>
      <div className={styles.statusItem}>
        <div className={styles.statusItemIcon}>
          <AccountAvatar
            className={styles.tooltipProfileAvatar}
            imageAsset={author?.keyImageImageAsset}
            initials={initials}
            size={AccountAvatarSize.Small}
          />
        </div>
        <Typography variant="cardTooltip">
          Created by <strong>you</strong>
        </Typography>
      </div>
      <Divider isSubtle spacing="none" theme="primary" />
      <div className={styles.statusItem}>
        <div className={styles.statusItemIcon}>
          <Eye size="md" />
        </div>
        <Typography variant="cardTooltip">
          <strong>Public</strong> to everyone
        </Typography>
      </div>
      {versionStatus === KittedVersionStatusTypeEnum.Draft && (
        <>
          <Divider isSubtle spacing="none" theme="primary" />
          <div className={styles.statusItem}>
            <div className={styles.statusItemIcon}>
              <TriangleExclaim color={styling.colors.theme.error} size="md" />
            </div>
            <Typography variant="cardTooltip">
              Contains <strong>unpublished changes</strong>
            </Typography>
          </div>
        </>
      )}
      {publishedAt && versionStatus !== KittedVersionStatusTypeEnum.Deleted && (
        <>
          <Divider isSubtle spacing="none" theme="primary" />
          <div className={styles.statusItem}>
            <div className={styles.statusItemIcon}>
              <Reset size="md" />
            </div>
            <Typography variant="cardTooltip">
              Published {publishedDate}
            </Typography>
          </div>
        </>
      )}
      {versionStatus === KittedVersionStatusTypeEnum.Deleted && (
        <>
          <Divider isSubtle spacing="none" theme="primary" />
          <div className={styles.statusItem}>
            <div className={styles.statusItemIcon}>
              <Trash size="md" />
            </div>
            <Typography variant="cardTooltip">Deleted</Typography>
          </div>
        </>
      )}
    </>
  );
};

export default memo(CardAuthorAvatarTooltipContent);
