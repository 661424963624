import { memo } from 'react';
import clsx from 'clsx';
import { TextSkeleton, Typography } from '@kitted/shared-components';
import { Hamburger, Plus, Times } from '@kitted/website-icons';

import menus from '../../../../constants/routes/menus';
import useIsLoggingIn from '../../../../contexts/UserContext/hooks/useIsLoggingIn';
import useMenuItemStyles from './MobileMenuItem/styles';
import MobileMenuItem from './MobileMenuItem';
import useStyles from './styles';
import { MobileMenuItemsProps } from './types';

const MobileMenuItems = ({
  isMenuOpen,
  onToggleMenuOpen,
  onMenuClose,
  isCreateOpen,
  onToggleCreateOpen,
  onCreateClose,
}: MobileMenuItemsProps) => {
  const styles = useStyles();
  const { isLoggingIn } = useIsLoggingIn();
  const menuItemStyles = useMenuItemStyles();

  return (
    <ul className={styles.list}>
      {menus.mobileMainMenu.map(
        ({ route: { path, title, target }, icon, highlightSubroutes }) => (
          <li key={path} className={styles.item}>
            <MobileMenuItem
              hideSelectedState={isMenuOpen || isCreateOpen}
              highlightSubroutes={highlightSubroutes}
              icon={icon}
              path={path}
              target={target}
              title={title}
            />
          </li>
        )
      )}
      <li className={styles.item}>
        <div
          className={clsx(
            styles.menuButton,
            menuItemStyles.container,
            isCreateOpen && menuItemStyles.containerSelected
          )}
          onClick={() => {
            onMenuClose();
            if (isLoggingIn) return;
            onToggleCreateOpen();
          }}
        >
          <div className={menuItemStyles.caret} />
          <span className={menuItemStyles.icon}>
            <Plus size="md" />
          </span>
          <Typography
            as="div"
            className={styles.createItem}
            variant="mobileNavigationLink"
          >
            {isLoggingIn ? (
              <TextSkeleton
                className={styles.skeleton}
                lines={1}
                variant="mobileNavigationLink"
              />
            ) : (
              'Create'
            )}
          </Typography>
        </div>
      </li>
      <li className={styles.item}>
        <button
          aria-label="Toggle menu"
          className={clsx(
            styles.menuButton,
            menuItemStyles.container,
            isMenuOpen && menuItemStyles.containerSelected
          )}
          onClick={() => {
            onCreateClose();
            onToggleMenuOpen();
          }}
          type="button"
        >
          <div className={menuItemStyles.caret} />
          <span className={menuItemStyles.icon}>
            {isMenuOpen && <Times size="sm" />}
            {!isMenuOpen && <Hamburger size="md" />}
          </span>
          <Typography variant="mobileNavigationLink">Menu</Typography>
        </button>
      </li>
    </ul>
  );
};

export default memo(MobileMenuItems);
