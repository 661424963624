/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import { ExternalLinkProps } from './types';

const ExternalLink = ({
  children,
  type,
  href,
  target = '_blank',
  rel = 'noopener',
  ...props
}: ExternalLinkProps) => (
  <a {...props} href={href} rel={rel} target={target}>
    {children}
  </a>
);

export default memo(ExternalLink);
