/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import useStyles from './styles';
import { WebsiteIconProps } from './types';

const WebsiteIcon: React.FC<WebsiteIconProps> = ({
  children,
  className,
  viewBox,
  size = 'inherit',
  ...props
}: WebsiteIconProps) => {
  const styles = useStyles();

  return (
    <svg
      {...props}
      className={clsx(className, styles.container, styles[`size-${size}`])}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

export default memo(WebsiteIcon);
