import { memo } from 'react';

import useContextualModalTitle from '../../../contexts/ModalContext/hooks/useContextualModalTitle';
import Button from '../../Button';
import EditDrawerSpaceFooter from '../../EditDrawerSpace/EditDrawerSpaceFooter';
import { ModelCreateEditEditModalSpaceProps } from '../types';
import useModelCreateEditFormFooter from './hooks/useModelCreateEditFormFooter';

const ModelCreateEditFormFooter = ({
  id,
  cardType,
  isCloning,
  onSaveChanges,
  onClose,
  title,
}: ModelCreateEditEditModalSpaceProps) => {
  const contextualTitle = useContextualModalTitle(title);
  const { buttonText, onSubmit, isDisabled } = useModelCreateEditFormFooter(
    id,
    cardType,
    isCloning,
    onSaveChanges,
    onClose
  );

  return (
    <EditDrawerSpaceFooter title={contextualTitle}>
      <Button fullWidth onClick={onClose} theme="primary" type="button">
        Cancel
      </Button>
      <Button
        fullWidth
        isDisabled={isDisabled}
        onClick={onSubmit}
        theme="primary"
        type="button"
        variant="solid"
      >
        {buttonText}
      </Button>
    </EditDrawerSpaceFooter>
  );
};

export default memo(ModelCreateEditFormFooter);
