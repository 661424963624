import { KeyboardEvent, memo } from 'react';
import clsx from 'clsx';
import { Typography } from '@kitted/shared-components';
import { Eye, EyeClosed } from '@kitted/website-icons';

import InputWrapper from '../InputWrapper';
import { InputComponentProps } from '../types';
import useInputPassword from './hooks/useInputPassword';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';
import useStyles from './styles';
import { InputPasswordMetaProp } from './types';

const InputPassword = ({
  autoComplete,
  className,
  errors,
  iconLeft,
  iconRight,
  disabled,
  isFocused,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  meta,
  name,
  value,
  theme,
  variant,
  required,
}: InputComponentProps & InputPasswordMetaProp) => {
  const styles = useStyles();
  const { handleOnChange, onToggleShow, isShowing, shouldHideErrors } =
    useInputPassword(onChange, onBlur, !!meta?.showStrengthIndicator);
  const hasRightIcon = iconRight || (!!value && (meta?.canShowHide as boolean));

  return (
    <>
      <InputWrapper
        className={clsx(className, shouldHideErrors && styles.shouldHideErrors)}
        errors={shouldHideErrors ? undefined : errors}
        hasValue={!!value}
        helperText={meta?.helperText}
        iconLeft={iconLeft}
        iconRight={!value ? iconRight : undefined}
        isDisabled={disabled}
        isFocused={isFocused}
        theme={theme}
        variant={variant}
      >
        <>
          <Typography
            as="input"
            autoComplete={autoComplete}
            autoFocus={meta?.autoFocus}
            className={clsx(
              styles.input,
              iconLeft && styles.inputWithLeftIcon,
              hasRightIcon && styles.inputWithRightIcon
            )}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onChange={handleOnChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown as (e: KeyboardEvent) => void}
            placeholder={meta?.placeholder as string}
            required={required}
            type={isShowing ? 'text' : 'password'}
            value={(value as number | string) || ''}
            variant={`input${variant === 'hollow' ? 'Small' : ''}`}
          />
          {meta?.canShowHide && (
            <div
              className={clsx(
                styles.showIcon,
                !!value && styles.showIconVisible
              )}
              onClick={onToggleShow}
            >
              {isShowing ? (
                <EyeClosed size="inherit" />
              ) : (
                <Eye size="inherit" />
              )}
            </div>
          )}
        </>
      </InputWrapper>
      {meta?.showStrengthIndicator && (
        <PasswordStrengthIndicator
          errors={errors}
          items={meta?.showStrengthIndicator.items}
        />
      )}
    </>
  );
};

export default memo(InputPassword);
