/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import { Image, Typography } from '@kitted/shared-components';
import { EMPTY_OBJ } from '@kitted/shared-utils';
import { Times } from '@kitted/website-icons';

import IconButton from '../../IconButton';
import ModalButtons from '../ModalButtons';
import useStyles from './styles';
import { DefaultModalProps } from './types';

const DefaultModalContent = ({
  preventClose,
  isRequired,
  onClose,
  meta,
  title,
  bodyComponent: BodyComponent,
  bodyComponentProps = EMPTY_OBJ,
}: DefaultModalProps) => {
  const styles = useStyles();

  return (
    <div className={styles.modal} onClick={preventClose}>
      {!isRequired && (
        <IconButton
          className={styles.closeButton}
          icon={Times}
          onClick={onClose}
          size="sm"
          theme="secondary"
          variant="outline"
        />
      )}
      {meta?.imgSrc && <Image className={styles.image} src={meta.imgSrc} />}
      <div
        className={clsx(
          styles.content,
          meta?.imgSrc && styles.contentWithImage
        )}
      >
        <Typography className={styles.title} variant="heading5">
          {title}
        </Typography>
        {BodyComponent && <BodyComponent {...bodyComponentProps} />}
        <ModalButtons buttons={meta?.buttons} onClose={onClose} />
      </div>
    </div>
  );
};

export default memo(DefaultModalContent);
