/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import FixedBottom from '../../FixedBottom';
import useStyles from './styles';
import { FullscreenModalProps } from './types';

const FullscreenModalContent = ({
  preventClose,
  onClose,
  isRequired,
  title,
  bodyComponent: BodyComponent,
  bodyComponentProps = EMPTY_OBJ,
}: FullscreenModalProps) => {
  const styles = useStyles();

  return (
    <FixedBottom className={styles.container} onClick={preventClose}>
      <BodyComponent
        {...bodyComponentProps}
        isRequired={isRequired}
        onClose={onClose}
        title={title}
      />
    </FixedBottom>
  );
};

export default memo(FullscreenModalContent);
