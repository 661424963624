/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import { Fade } from '@kitted/shared-components';

import Backdrop from '../Backdrop';
import useModal from './hooks/useModal';
import useStyles from './styles';
import { ModalProps } from './types';

const Modal = (props: ModalProps) => {
  const {
    isTop,
    hasBackdrop = true,
    isRequired = false,
    shouldReturnFocus = true,
  } = props;
  const {
    isIn,
    style,
    onClose,
    onExited,
    ModalContentComponent,
    modalContentProps,
  } = useModal(props);
  const styles = useStyles();

  return (
    <>
      <FocusTrap
        active={isTop}
        focusTrapOptions={{
          allowOutsideClick: true,
          returnFocusOnDeactivate: shouldReturnFocus,
        }}
      >
        <Fade
          appear
          className={clsx(
            styles.container,
            isRequired && styles.containerRequired
          )}
          durationIn={500}
          durationOut={500}
          in={isIn}
          onClick={isRequired ? undefined : onClose}
          onExited={onExited}
          style={style}
        >
          <ModalContentComponent {...modalContentProps} />
        </Fade>
      </FocusTrap>
      {hasBackdrop && <Backdrop variant="modal" />}
    </>
  );
};

export default memo(Modal);
