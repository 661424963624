import { memo } from 'react';
import { styling } from '@kitted/design-system';

import { NCProps } from './types';

const NC = ({
  className,
  height = 26,
  width = 26,
  fill = styling.colors.theme.primary600,
}: NCProps) => (
  <svg
    className={className}
    height={height}
    viewBox="0 0 26 26"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={fill}>
      <path d="m12.98,1.07c3.35,0,6.17,1.15,8.48,3.46,2.31,2.31,3.47,5.13,3.47,8.47s-1.14,6.14-3.41,8.38c-2.41,2.37-5.26,3.55-8.54,3.55s-6.04-1.17-8.39-3.52c-2.35-2.35-3.52-5.15-3.52-8.41s1.17-6.09,3.52-8.47c2.29-2.31,5.09-3.46,8.39-3.46ZM3.75,9.79c-.36,1.01-.53,2.08-.53,3.21,0,2.64.97,4.93,2.9,6.86,1.93,1.93,4.23,2.89,6.9,2.89s4.99-.97,6.96-2.92c.66-.64,1.21-1.33,1.63-2.09l-4.5-2c-.3,1.51-1.65,2.54-3.28,2.66v1.84h-1.37v-1.84c-1.34-.02-2.63-.56-3.62-1.43l1.64-1.66c.79.75,1.58,1.08,2.66,1.08.7,0,1.48-.27,1.48-1.19,0-.32-.12-.55-.32-.72l-1.14-.51-1.42-.63c-.7-.31-1.29-.58-1.89-.84l-6.1-2.71ZM13.01,3.21c-2.71,0-5,.95-6.87,2.86-.51.51-.95,1.05-1.33,1.61l4.56,2.03c.41-1.27,1.62-2.03,3.08-2.12v-1.84h1.37v1.84c.94.05,1.98.3,3,1.09l-1.57,1.61c-.58-.41-1.31-.7-2.04-.7-.59,0-1.43.18-1.43.93,0,.11.04.21.11.3l1.53.68,1.03.46c.66.29,1.29.57,1.92.85l6.12,2.72c.2-.8.3-1.65.3-2.55,0-2.73-.96-5.03-2.87-6.92-1.89-1.91-4.19-2.86-6.9-2.86Z" />
    </g>
  </svg>
);

export default memo(NC);
