import ReactDomServer from 'react-dom/server';
import { JssProvider, SheetsRegistry } from 'react-jss';
import { AnyCardModel } from '@kitted/card-service-models';

import GlobalStyles from '../../components/GlobalStyles';
import RenderCardWideComponent from './RenderCardWideComponent';

const renderCardWide = ({
  card,
  authorName,
  baseDomain,
  fontBaseDomain,
  scale = 1,
}: {
  card: AnyCardModel<true>;
  authorName?: string;
  baseDomain: string;
  fontBaseDomain: string;
  scale?: number;
}) => {
  const sheets = new SheetsRegistry();

  const html = ReactDomServer.renderToString(
    <JssProvider registry={sheets}>
      <GlobalStyles />
      <RenderCardWideComponent
        authorName={authorName}
        baseUrl={baseDomain}
        card={card}
      />
    </JssProvider>
  );

  const css = sheets.toString();

  return `
  <!doctype html>
  <html lang="en">
    <head>
      <meta charset="utf-8">
      <base href="${fontBaseDomain}">
      <style id="jss-server-side">${css}</style>
    </head>
    <body class="no-animations" style="background-color: transparent !important; -webkit-font-smoothing: antialiased;">
      <div id="root" style="transform: scale(${scale}); transform-origin: top left;">${html}</div>
    </body>
  </html>
`;
};

export default renderCardWide;
