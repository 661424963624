import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Nekuodah } from '@kitted/shared-components';

import AlertRoot from '../../components/AlertRoot';
import AuthTokensRefreshTimer from '../../components/AuthTokensRefreshTimer';
import DrawerSpace from '../../components/DrawerSpace';
import RouteLoader from '../../components/Loaders/RouteLoader';
import ModalRoot from '../../components/ModalRoot';
import ScrollRestoration from '../../components/ScrollRestoration';
import ToastRoot from '../../components/ToastRoot';
import useMediaQuery from '../../contexts/MediaQueryManagementContext/hooks/useMediaQuery';
import useClearSkipBlockerOnLocationChange from './hooks/useClearSkipBlockerOnLocationChange';
import useModalCloseOnLocationChange from './hooks/useModalCloseOnLocationChange';
import useRemoveDefaultSEOHeadValues from './hooks/useRemoveDefaultSEOHeadValues';
import useSetDefaultDateFnsOptions from './hooks/useSetDefaultDateFnsOptions';
import AnalyticsTriggers from './AnalyticsTriggers';
import { providers } from './constants';
import FixedMobileMenu from './FixedMobileMenu';
import Footer from './Footer';
import MainMenuDrawer from './MainMenuDrawer';

const Root = () => {
  const isTablet = useMediaQuery('isTablet');
  useSetDefaultDateFnsOptions();
  useRemoveDefaultSEOHeadValues();
  useModalCloseOnLocationChange();
  useClearSkipBlockerOnLocationChange();

  return (
    <>
      <AuthTokensRefreshTimer />
      <Nekuodah components={providers}>
        <DrawerSpace
          isMainDisabled={!isTablet}
          mainDrawerContent={MainMenuDrawer}
        >
          <RouteLoader />
          <Outlet />
          <Footer />
          {!isTablet && <FixedMobileMenu />}
        </DrawerSpace>
        <ScrollRestoration />
        <AlertRoot />
        <ModalRoot />
        <ToastRoot />
        <AnalyticsTriggers />
      </Nekuodah>
    </>
  );
};

export default memo(Root);
