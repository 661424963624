/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';

import AuthenticationModalContentWrapper from '../AuthenticationModalContentWrapper';
import useAuthChallengeModalContent from './hooks/useAuthChallengeModalContent';
import { AuthChallengeModalContentProps } from './types';

const AuthChallengeModalContent = ({
  onClose,
  meta,
  preventClose,
  title,
  isRequired,
}: AuthChallengeModalContentProps) => {
  const { ChallengeComponent, challengeComponentProps } =
    useAuthChallengeModalContent(meta, onClose);

  return (
    <AuthenticationModalContentWrapper
      isRequired={isRequired}
      onClose={onClose}
      preventClose={preventClose}
      title={title}
    >
      {ChallengeComponent && (
        <ChallengeComponent onClose={onClose} {...challengeComponentProps} />
      )}
    </AuthenticationModalContentWrapper>
  );
};

export default memo(AuthChallengeModalContent);
