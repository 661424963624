/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import Fade from '../../Animations/Fade';
import MediaPlayer from '../../MediaPlayer';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useStyles from './styles';
import { PolymorphicDisplayCardProps } from './types';

const DisplayCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  imageSrc,
  isActive,
  isFeatured,
  isMenuOpen,
  title,
  videoUrl,
  autoPlay,
  actionButtonTitle,
  ...rest
}: PolymorphicDisplayCardProps<C>) => {
  const styles = useStyles();
  const Component = as || 'div';
  const interactionProps = useExtractInteractionProps(rest);

  return (
    <Component
      className={clsx(className, styles.container, styles.containerActive)}
      {...interactionProps}
    >
      <CardImage imageSrc={imageSrc} isFeatured={isFeatured} />

      <div className={styles.videoContainer}>
        {videoUrl && (
          <Fade
            className={styles.videoPlayerContainer}
            in={isActive || autoPlay}
            unmountOnExit={false}
          >
            <MediaPlayer
              className={styles.mediaPlayer}
              initialState={{ playing: autoPlay, muted: true }}
              loop
              playerType="video"
              playing={isActive || autoPlay}
              sources={videoUrl}
            />
          </Fade>
        )}
      </div>
      {actionButtonTitle && (
        <div
          className={clsx(
            styles.actionButtonTitle,
            isActive && styles.actionButtonTitleHover
          )}
        >
          {actionButtonTitle}
        </div>
      )}

      {children}
    </Component>
  );
};

export default memo(DisplayCard);
