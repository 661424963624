/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import { EMPTY_OBJ } from '@kitted/shared-utils';

import DrawerSpace from '../DrawerSpace';
import { EditDrawerSpaceProvider } from './contexts/EditDrawerSpaceContext';
import useEditDrawerSpace from './hooks/useEditDrawerSpace';
import DefaultEditDrawerHeader from './DefaultEditDrawerHeader';
import EditDrawerSpaceContentBody from './EditDrawerSpaceContentBody';
import EditDrawerSpaceContentHeaderBar from './EditDrawerSpaceContentHeaderBar';
import EditDrawerSpaceDrawerContent from './EditDrawerSpaceDrawerContent';
import EditDrawerSpaceMobileMenu from './EditDrawerSpaceMobileMenu';
import useStyles from './styles';
import { EditDrawerSpaceProps } from './types';

const EditDrawerSpace = ({
  items,
  isDisabled,
  initialSelectedDrawerItemSlug,
  drawerHeader,
  footer,
  footerHeight,
  spaceProps = EMPTY_OBJ,
  bodyHeader: BodyHeader,
}: EditDrawerSpaceProps) => {
  const styles = useStyles();
  const { isTablet } = useEditDrawerSpace();

  return (
    <EditDrawerSpaceProvider
      initialSelectedDrawerItemSlug={initialSelectedDrawerItemSlug}
      items={items}
      spaceProps={spaceProps}
    >
      <DrawerSpace
        footer={footer}
        footerHeight={footerHeight}
        isMainDisabled={isDisabled || !isTablet}
        mainDrawerContent={EditDrawerSpaceDrawerContent}
        mainDrawerHeader={drawerHeader || DefaultEditDrawerHeader}
      >
        <div className={styles.container}>
          {BodyHeader && (
            <EditDrawerSpaceContentHeaderBar>
              <BodyHeader {...spaceProps} />
            </EditDrawerSpaceContentHeaderBar>
          )}
          <EditDrawerSpaceContentBody />
          {!isTablet && <EditDrawerSpaceMobileMenu />}
        </div>
      </DrawerSpace>
    </EditDrawerSpaceProvider>
  );
};

export default memo(EditDrawerSpace);
