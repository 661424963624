/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import clsx from 'clsx';

import CardContents from '../CardContents';
import CardIcon from '../CardIcon';
import CardImage from '../CardImage';
import useExtractInteractionProps from '../hooks/useExtractInteractionProps';
import useToolCard from './hooks/useToolCard';
import useStyles from './styles';
import { PolymorphicToolCardProps } from './types';

const ToolCard = <C extends React.ElementType = 'div'>({
  as,
  body,
  children,
  className,
  slug,
  imageSrc,
  isFeatured,
  isMenuOpen,
  subType,
  title,
  ...rest
}: PolymorphicToolCardProps<C>) => {
  const interactionProps = useExtractInteractionProps(rest);
  const styles = useStyles();
  const { style } = useToolCard(subType);
  const Component = as || 'div';

  return (
    <Component
      className={clsx(
        className,
        styles.container,
        isMenuOpen && styles.containerMenuOpen
      )}
      style={style}
      {...interactionProps}
    >
      {isFeatured ? (
        <CardImage halfHeight imageSrc={imageSrc} isFeatured />
      ) : (
        <div className={styles.cardIconContainer}>
          <CardIcon slug={slug} />
        </div>
      )}
      <CardContents
        body={body}
        isFeatured={isFeatured}
        title={title}
        type="tool"
        variant={isFeatured ? 'belowMiddle' : 'straddleMiddle'}
      />
      {children}
    </Component>
  );
};

export default memo(ToolCard);
