import { memo } from 'react';

import MobileMenuOption from '../MobileMenuOption';
import useLoginOutItem from './hooks/useLoginOutItem';
import { LoginOutItemProps } from './types';

const LoginOutItem = ({ onToggleOpen }: LoginOutItemProps) => {
  const { onClick, title, IconComponent, isLoggingIn } =
    useLoginOutItem(onToggleOpen);

  return (
    <MobileMenuOption
      icon={IconComponent}
      isLoading={isLoggingIn}
      onClick={onClick}
      title={title}
    />
  );
};

export default memo(LoginOutItem);
